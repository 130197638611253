import { SxProps } from '@mui/material';

export const myCardsSelectorCardSxPropsXL: SxProps = {
  '&.MuiBox-root.CardContainer': {
    width: '98px',
    height: '58px',
    borderRadius: '4px',
    '.Card': {
      padding: '8px 10px',
    },
    '.CardHeaderWrapperLogo': {
      gap: '4px',
    },
    '.CardHeaderPaymentLogo, .CardHeaderAdditionalLogo': {
      height: '15px',
    },
    '.CardHeaderCardTier': {
      fontSize: '4px',
      lineHeight: '100%',
      letterSpacing: '0.034px',
      height: 'max-content',
    },
    '.Card_Visa .CardHeaderPaymentLogo': {
      width: '38px',
      height: '12px',
    },
    '.Card_VisaApplePay .CardHeaderPaymentLogo': {
      height: '15px',
    },
    '.CardHeaderPaymentInfo': {
      gap: '2px',
    }
  },
};

export const myCardsSelectorCardSxPropsSM: SxProps = {
  '&.MuiBox-root.CardContainer': {
    width: '69px',
    height: '40px',
    borderRadius: '4px',
    '.Card': {
      padding: '6px 7px',
    },
    '.CardHeaderWrapperLogo': {
      gap: '2px',
    },
    '.CardHeaderPaymentLogo, .CardHeaderAdditionalLogo': {
      width: 'auto',
      height: '11px',
    },
    '.CardHeaderCardTier': {
      fontSize: '3px',
      lineHeight: '100%',
      letterSpacing: '0.034px',
      height: 'max-content',
    },
    '.Card_Visa .CardHeaderPaymentLogo': {
      width: 'auto',
      height: '8px',
    },
    '.Card_VisaApplePay .CardHeaderPaymentLogo': {
      width: 'auto',
      height: '11px',
    },
    '.CardHeaderPaymentInfo': {
      gap: '2px',
    }
  },
}

