import classNames from 'classnames';
import type { ComponentProps } from 'react';
import Icon, { IIcon } from '../Icon';
import styles from './ActionBtn.module.scss';
import { forwardRef } from 'react';
import { Box, SxProps } from '@mui/material';
import { Theme as SystemTheme } from '@mui/system/createTheme/createTheme';
import Loader from '../Loader';

export const ActionBtn = forwardRef<any, {
  prefixIcon?: IIcon;
  suffixIcon?: IIcon;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  className?: string;
  onClick?: () => void | undefined
  buttonProps?: ComponentProps<'button'>;
  children?: React.ReactNode;
  text?: string;
  sx?: SxProps<SystemTheme>;
  isLoading?: boolean;
}>((props, ref) => {
  return (
    <Box
      component="button"
      ref={ref}
      type={props.type}
      disabled={props.disabled}
      className={classNames(props.className, styles.ActionBtn, 'ActionBtn')}
      onClick={props?.onClick}
      sx={props.sx}
    >
      {props.prefixIcon && (
        <div className={classNames(styles['ActionBtn-Icon'], 'ActionBtn-Icon', {'tw-bg-transparent': props.isLoading})}>
          {props.isLoading ? <Loader spinsPerSecond={3}/> : <Icon icon={props.prefixIcon}/> }
        </div>
      )}

      {props.text && <span>{props.text}</span>}

      {props.suffixIcon && (
        <div className={classNames(styles['ActionBtn-Icon'], 'ActionBtn-Icon', { 'tw-bg-transparent': props.isLoading })}>
          {props.isLoading ? <Loader spinsPerSecond={3}/> : <Icon icon={props.suffixIcon}/>}
        </div>
      )}
  </Box>);
});