import { SxProps, Theme } from '@mui/material';

export const actionBtnSmallSx: SxProps<Theme> = {
  fontSize: 12,
  height: 30,
  gap: '10px',
  '.ActionBtn-Icon': {
    width: 30,
    height: 30,
    '.SvgIcon': {
      width: 14,
    },
  },
}