import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import styles from './MyCardSelector.module.scss';
import { FC } from 'react';
import { ICardDto } from '../../../../../../services/card-api';
import { Card } from './../../../../Cards/components/Card/Card.tsx';
import { formatCardNumber, maskCardNumber } from './../../../../../../helpers/card-number.helper.ts';
import { useBreakpoint } from '../../../../../../hooks/useBreakpoint.ts';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../../../helpers/breakpoints.ts';
import { myCardsSelectorCardSxPropsSM, myCardsSelectorCardSxPropsXL } from './my-card-selector-card-sx-props.ts';
import { Box, SxProps } from '@mui/material';

interface ICardSelectorProps {
  cards: ICardDto[];
  activeCard: ICardDto | null;
  onCardClick: (card: ICardDto) => void;
  sx?: SxProps;
}

export const MyCardsSelector: FC<ICardSelectorProps> = observer(
  ({ cards, activeCard, onCardClick, sx }) => {
    const breakpoint = useBreakpoint();
    const isDesktop = isBreakpointGreaterThan(breakpoint, Breakpoints.XL);

    return (
      <Box className="tw-flex tw-gap-1.5 xl:tw-gap-2.5" sx={sx}>
        {cards.map((card: ICardDto) => (
          <div className={cn(styles.Option, {[styles.Option_active]: activeCard?.id === card.id })} key={card.id}>
            <Card
              sx={isDesktop ? myCardsSelectorCardSxPropsXL : myCardsSelectorCardSxPropsSM}
              cardType={card.cardType}
              isShowBalance={false}
              isShowCardNumber={false}
              isShowCvvAndDate={false}
              isShowKindCard={false}
              onClick={() => onCardClick(card)}
            ></Card>
            <div className={cn(styles.OptionCardNumber, 'tw-text-black-700 tw-font-medium')}>
              {formatCardNumber(maskCardNumber(card.number))}
            </div>
          </div>
        ))}
      </Box>
    );
  }
);
