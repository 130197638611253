import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import styles from './TopUpCard.module.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  cardApiService,
  CardTypeMastercardPhysical,
  CardTypeVisaPhysical,
  Currency,
  IDepositDto
} from '../../../../../services/card-api';
import { createCardDetailStore } from '../store';
import classNames from 'classnames';
import Button from '../../../../../components/Button';
import { Card } from '../../components/Card/Card.tsx';
import CopyValue from '../../../../../components/CopyValue';
import QRCode, { toCanvas } from 'qrcode';
import Loader from '../../../../../components/Loader';
import { exchangeRateService } from '../../../../../services/exchange-api/exchange-api.service.ts';
import { isPhysicalCard } from '../../../../../helpers/card-kind.helper.ts';
import { PageContainer } from '../../../../../components/PageContainer/PageContainer.tsx';
import { useBreakpoint } from '../../../../../hooks/useBreakpoint.ts';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../../helpers/breakpoints.ts';
import { cardDesktopSxProps } from './card-desktop-sx-props.ts';
import { cardSmSXProps } from '../card-sm-sx-props.ts';
import cn from 'classnames';
import { ActionBtn } from '../../../../../components/ActionBtn/ActionBtn.tsx';
import onError from '../../../../../helpers/onError.ts';
import { TopUpSectionInfo } from './components/TopUpInfoSection/TopUpInfoSection.tsx';

export const TopUpCard = observer(() => {
  const { cardId } = useParams() as { cardId: string };
  const navigate = useNavigate();
  const cardStore = useLocalObservable(() => createCardDetailStore());
  const [selectedCoin, setSelectedCoin] = useState<string | null>(null);
  const [selectedChain, setSelectedChain] = useState<string | null>(null);
  const [deposit, setDepositState] = useState<{ isLoading: boolean; data: IDepositDto | null }>({
    isLoading: false,
    data: null,
  });
  const [exchange, setExchangeState] = useState<{ isLoading: boolean; rate: number | null }>({
    isLoading: false,
    rate: null,
  });
  const breakpoint = useBreakpoint();
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);

  const isDesktop = isBreakpointGreaterThan(breakpoint, Breakpoints.XL);

  const loadExchangeRate = async () => {
    setExchangeState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const res = await exchangeRateService.exchangeRate();
      setExchangeState({ isLoading: false, rate: res?.rate ?? 0 });
    } catch (error) {
      setExchangeState({ isLoading: false, rate: null });
    }
  };

  const loadWalletAddress = async (cardId: string) => {
    try {
      setDepositState((prevState) => ({ ...prevState, isLoading: true }));
      const depositData = await cardApiService.getDeposit(cardId);
      setDepositState({ isLoading: false, data: depositData });
    } catch (error) {
      setDepositState({ isLoading: false, data: null });
    }
  };

  useEffect(() => {
    cardStore.loadCardData(cardId);
  }, [cardId]);

  useEffect(() => {
    if (cardStore.cardData?.freezed) navigate('/app/cards');
  }, [cardStore.cardData]);

  useEffect(() => {
    if (deposit.data?.usdtTrcAddress) {
      QRCode.toDataURL(deposit.data.usdtTrcAddress, { margin: 2 }).then(setQrCodeUrl).catch(onError);
    }
  }, [deposit.data?.usdtTrcAddress]);

  useEffect(() => {
    if (selectedCoin && selectedChain) {
      loadWalletAddress(cardId);
      if (isPhysicalCard(cardStore.cardData?.cardType)) loadExchangeRate();
    }
  }, [selectedCoin, selectedChain]);

  return (
    <PageContainer isRegular>
      <div className={classNames(styles.TopUpContent)}>
        <section>
          <h5 className="tw-hidden tw-text-1.5xl xl:tw-block">Your card</h5>
          <div className="xl:tw-mt-6">
            <div className={classNames(styles.CardWrapper, 'tw-flex-centering tw-mx-auto')}>
              {cardStore.loading && <Loader/>}
              {!cardStore.loading && cardStore.cardData && (
                <Card
                  isLocked={cardStore.cardData.freezed}
                  cardType={cardStore.cardData.cardType}
                  cardData={cardStore.cardData}
                  cardBalance={cardStore.balance}
                  containerClassNames="tw-h-full tw-w-full"
                  sx={isDesktop ? cardDesktopSxProps : cardSmSXProps}
                ></Card>
              )}
            </div>
            {selectedCoin && selectedChain && isDesktop &&
              <TopUpSectionInfo
                cardType={cardStore.cardData?.cardType}
                exchangeRate={exchange.rate}
                isLoadingExchangeRate={exchange.isLoading}
                className="tw-mt-5"
              />
            }
          </div>
        </section>
        <section className="tw-mt-7 xl:tw-mt-0">
          <h5 className="tw-text-1.5xl">Deposit</h5>
          <div className={classNames(styles.DepositSection, 'tw-mt-6 tw-bg-secondary-75')}>
            <div>Choose coin</div>
            <div className="tw-flex tw-flex-col tw-mt-5 xl:mt-0">
              <div className={cn(
                styles.SelectionCoinDeposit,
                { [styles.SelectionCoinDeposit_selectedChain]: selectedChain }
              )}>
                <div className={styles.SelectionCoin}>
                  <div className="tw-text-secondary tw-text-sm">
                    {selectedChain ? 'Coin' : 'Specify the coin to be deposit'}
                  </div>
                  <div className="tw-mt-5">
                    <Button
                      className={styles.SelectBtn}
                      additional={{
                        isGray: !selectedCoin,
                        isSoftGreen: !!selectedCoin,
                      }}
                      onClick={() => setSelectedCoin('usdt')}
                    >
                      <img
                        src="/images/usdt-icon.svg"
                        className={cn(styles.UsdtIcon, `${selectedCoin ? '' : 'tw-grayscale'}`)}
                        alt="usdt-icon"
                      />
                      USDT
                    </Button>
                  </div>
                </div>
                {selectedCoin &&
                    <div className={styles.SelectionCoin}>
                        <div className="tw-text-secondary tw-text-sm">Chain type</div>
                        <div className="tw-mt-5">
                            <Button
                                className={styles.SelectBtn}
                                additional={{
                                  isGray: selectedChain !== 'tron',
                                  isSoftGreen: selectedChain === 'tron',
                                }}
                                onClick={() => setSelectedChain('tron')}
                            >
                                <img
                                    src="/images/tron-icon.svg"
                                    className={cn(styles.TronIcon, `${selectedChain ? '' : 'tw-grayscale'}`)}
                                    alt="tron-icon"
                                />
                                Tron
                            </Button>
                        </div>
                    </div>
                }
              </div>
              {
                selectedChain &&
                  <div className="tw-flex tw-flex-col tw-flex-1 tw-mt-7 xl:tw-mt-9">
                      <div className="tw-text-sm">
                          To deposit USDT please go to your wallet and send to the USDT wallet address below.
                          To avoid typing address, copy and paste.
                      </div>
                      <div className="tw-flex tw-items-center tw-gap-2.5 xl:tw-gap-11 tw-mt-4 xl:tw-mt-5">
                        {
                          deposit.isLoading
                            ? <div className="tw-flex-centering tw-w-full"><Loader/></div>
                            : <>
                              <div className={classNames(
                                styles.WalletQrCodeWrapper,
                                'tw-flex-centering tw-border tw-border-black tw-rounded-lg xl:tw-rounded-2xl tw-overflow-hidden'
                              )}>
                                {qrCodeUrl && <img src={qrCodeUrl} className="tw-w-full tw-h-full" alt="Wallet qr code" />}
                              </div>
                              <div className="tw-text-xs">
                                <div className="tw-text-secondary xl:tw-text-sm">
                                  Wallet address
                                </div>
                                <div className="tw-flex tw-items-center tw-gap-1.5 tw-mt-1.5 xl:tw-gap-3 xl:tw-mt-2 xl:tw-text-base">
                                  <span className="tw-break-all">{deposit.data?.usdtTrcAddress}</span>
                                  <CopyValue
                                    value={deposit.data?.usdtTrcAddress ?? ''}
                                    className={styles.CopyWalletIcon}
                                  />
                                </div>
                              </div>
                            </>
                        }
                      </div>
                      <div className="tw-flex-1 tw-mt-5 xl:tw-mt-6.5 tw-text-sm">
                          <p>Be aware!</p>
                          <div className="tw-mt-4">
                              <p>
                                  1. Verify the deposit chain matches the chain you are sending form. Funds sent
                                  to an incorrect address may be irrecoverably lost.
                              </p>
                              <p>
                                  2. Only send USDT to this address. Depositing any other token may result in permanent
                                  of funds.
                              </p>
                          </div>
                      </div>
                    {
                      isDesktop &&
                        <Link className="tw-mt-4 tw-ml-auto" to="/app/cards">
                            <ActionBtn suffixIcon="custom-arrow-1" text="Complete" className="tw-text-secondary"/>
                        </Link>
                    }
                  </div>
              }
            </div>
          </div>
        </section>
      </div>
      {selectedCoin && selectedChain && !isDesktop &&
        <TopUpSectionInfo
          cardType={cardStore.cardData?.cardType}
          exchangeRate={exchange.rate}
          isLoadingExchangeRate={exchange.isLoading}
          className="tw-mt-3"
        />
      }
      {
        !isDesktop &&
          <Link className="tw-mt-8 tw-ml-auto" to="/app/cards">
              <ActionBtn suffixIcon="custom-arrow-1" text="Complete" />
          </Link>
      }
    </PageContainer>
  );
});
