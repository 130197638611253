import { forwardRef } from 'react';
import KYCStepOne from './components/KYCStepOne/KYCStepOne.tsx';
import KYCStepTwo from './components/KYCStepTwo/KYCStepTwo.tsx';

interface IKYCMCPhysical {
  step: number,
  onNextStepClick: () => void,
  formState: any,
  setFormState: any,
  isLoading?: boolean,
}

export const KYCVisaPhysical = forwardRef<any, IKYCMCPhysical>(({
  step,
  onNextStepClick,
  formState,
  setFormState,
  isLoading,
}, ref) => {
  return (
    <>
      {step === 1 && (
        <KYCStepOne
          ref={ref}
          setFormState={setFormState}
          onNextStepClick={onNextStepClick}
          formState={formState}
        />
      )}
      {step === 2 && (
        <KYCStepTwo
          ref={ref}
          onNextStepClick={onNextStepClick}
          formState={[formState, setFormState]}
          isLoading={isLoading}
        />
      )}
    </>
  )
});