import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import Icon from '../../../../../../components/Icon';

interface IStepBlockInfoProps {
  title?: string;
  content?: string;
  icon: string;
  iconClassName?: string;
  children?: ReactNode;
  className?: string;
}

export const StepBlockInfo: FC<IStepBlockInfoProps> = ({ title, icon, children, iconClassName, className }) => {
  return (
    <div
      className={classNames(
        className,
        "tw-flex tw-flex-col tw-justify-between tw-rounded-1.5xl tw-px-3 tw-py-3.5 xl:tw-py-4 xl:tw-px-4.5 tw-bg-secondary-200",
      )}>
      <div className="tw-flex tw-gap-1 tw-items-center tw-text-base xl:tw-text-xl">
        <div className="tw-flex-centering tw-size-5 xl:tw-size-6 tw-bg-softGreen tw-rounded-full">
          <Icon icon={icon} className={iconClassName} />
        </div>
        {title}
      </div>
      <div className="tw-text-xs xl:tw-text-2sm">{children}</div>
    </div>
  )
};
