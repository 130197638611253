import classNames from 'classnames';
import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import Icon from '../Icon';
import styles from './index.module.scss';

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  className?: string;
  style?: CSSProperties;
  closeVariant?: 'cross' | 'text';
  children: ReactNode;
}

export const Modal = (props: ModalProps) => {
  // state
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // modal ref
  const modalRef = useRef<HTMLDivElement | null>(null);
  const modalWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // hide scroll when modal is open
    const body = document.getElementsByTagName('body')[0];
    if (props.isOpen) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }
    
    const handleOutsideClick = (event: MouseEvent) => {
      if (modalRef.current) {
        const withinBoundaries = event
          .composedPath()
          .includes(modalRef.current);
        if (!withinBoundaries) {
          props.onRequestClose();
        }
      }
    };
    
    modalWrapperRef.current?.addEventListener('click', handleOutsideClick);

    setTimeout(() => {
      setModalIsOpen(props.isOpen);
    }, 100);
    
    return () => {
      modalWrapperRef.current?.removeEventListener('click', handleOutsideClick);
      body.style.overflow = 'auto';
    };
  }, [props.isOpen]);
  
  return (
    <div
      ref={modalWrapperRef}
      style={props.style}
      className={classNames(styles.modal, {
        isClosed: !props.isOpen ? !props.isOpen : !props.isOpen || !modalIsOpen,
        isOpen: props.isOpen ? props.isOpen : props.isOpen || modalIsOpen
      })}>
      <div
        ref={modalRef}
        className={classNames(props.className, styles.content, {
          isClosed: !props.isOpen
        })}>
        {props.closeVariant !== 'text' && (
          <button
            className={styles.closeModalBtn}
            onClick={props.onRequestClose}>
            <Icon icon="custom-cross-1" />
          </button>
        )}
        {props.closeVariant === 'text' && (
          <button
            className={styles.closeModalBtnText}
            onClick={props.onRequestClose}>
            Close
          </button>
        )}
        {props.children}
      </div>
    </div>
  );
};
