import React, { useState } from 'react';
import styles from './index.module.css';
import classNames from 'classnames';
import { useNavigate } from "react-router-dom";
import {
  CardType,
  CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeVisaPhysical
} from '../../../../services/card-api';
import { cardKindString } from '../../../../helpers/card-kind.helper.ts';
import { CheckmarkStatus } from './components/CheckmartStatus.tsx';
import { logoCardClassMap } from '../../../../helpers/card-brand.helper.ts';
import { getCardTier } from '../../../../helpers/card-tier.helper.ts';
import Button from '../../../../components/Button';
import Select from 'react-select';
import {
  DropdownIndicator
} from '../../../../components/CustomReactSelect/components/DropdownIndicator/DropdownIndicator.tsx';
import { GroupHeading } from '../../../../components/CustomReactSelect/components/GroupHeading/GroupHeading.tsx';
import { getCardFeature, IAvailableCardTypes, ICardFeature } from './card-feature.ts';
import { cardOptionsGroup } from './cards-options.ts';
import { PageContainer } from '../../../../components/PageContainer/PageContainer.tsx';

const initialSelectedCards = [
  getCardFeature(CardTypeMastercardPhysical.PhysicalMastercard),
  getCardFeature(CardTypeVisaPhysical.PhysicalVisa),
  getCardFeature(CardTypeApplePay.VirtualApplePay),
];

const CardsComparePage: React.FC = () => {
  const navigate = useNavigate();
  const [selectedCards, setSelectedCards] = useState<ICardFeature[]>(initialSelectedCards);
  
  const handleLearnMoreClick = (cardType: CardType) => {
    navigate(`/app/cards/get-my-card?cardType=${cardType}`);
  };
  
  const handleSelectCard = (cardType: IAvailableCardTypes, index: number) => {
    const selectedCard = getCardFeature(cardType);
    setSelectedCards((prevState) => {
      const copyPrevState = [...prevState];
      copyPrevState[index] = selectedCard;
      return copyPrevState;
    });
  }

  return (
    <PageContainer>
      <div className="regular-page">
        <h1 className={styles.title}>Compare</h1>
        <div className={styles.contentBlock}>
          <table className="tw-table-fixed tw-w-full">
            <thead>
            <tr>
              <th></th>
              {selectedCards.map(({ cardType }, i) => {
                const defaultOption = [...cardOptionsGroup]
                  .flatMap(group => [...group.options])
                  .find(option => option.value === cardType);

                return (
                  <th className={classNames(styles.cell, 'tw-pt-0 tw-h-auto')} key={i}>
                    <Select
                      className={styles.SelectCard}
                      options={cardOptionsGroup}
                      classNamePrefix="default-select"
                      isSearchable={false}
                      value={defaultOption}
                      onChange={(option: any) => handleSelectCard(option.value, i)}
                      components={{ DropdownIndicator, GroupHeading }}
                    />
                  </th>
                )
              })}
            </tr>
            </thead>
            <tbody>
            <tr className="tw-border-secondary-300 tw-border-b">
              <td className={classNames(styles.cell, 'tw-align-bottom tw-text-secondary tw-pt-0')}>
                Basic information
              </td>
              {selectedCards.map(({ cardImageScr, cardNetwork }, i) => {
                return (
                  <td className={classNames(styles.cell, 'tw-pt-0')} key={i}>
                    <img
                      src={`/images/${cardImageScr}`}
                      alt={cardNetwork}
                      className={styles.CardImg}
                    />
                  </td>
                )
              })}
            </tr>
            <tr className="tw-border-secondary-300 tw-border-b">
              <td className={styles.cell}>Card type:</td>
              {selectedCards.map(({ cardType }, i) =>
                <td className={styles.cell} key={i}>{cardKindString(cardType) + ' card'}</td>
              )}
            </tr>
            <tr className="tw-border-secondary-300 tw-border-b">
              <td className={styles.cell}>Card network:</td>
              {selectedCards.map(({ cardNetwork }, i) =>
                <td className={styles.cell} key={i}>{cardNetwork}</td>
              )}
            </tr>
            <tr className="tw-border-secondary-300 tw-border-b">
              <td className={styles.cell}>Currency:</td>
              {selectedCards.map(({ currency }, i) =>
                <td className={styles.cell} key={i}>{currency}</td>
              )}
            </tr>
            <tr className="tw-border-secondary-300 tw-border-b">
              <td className={styles.cell}>Funding method:</td>
              {selectedCards.map(({ fundingMethod }, i) =>
                <td className={styles.cell} key={i}>{fundingMethod}</td>
              )}
            </tr>
            <tr>
              <td className={styles.sectionTitle} colSpan={4}>
                Features
              </td>
            </tr>
            <tr className="tw-border-secondary-300 tw-border-b">
              <td className={styles.cell}>Payment for goods and services</td>
              {selectedCards.map(({ paymentForGoodsAndServices }, i) =>
                <td className={styles.cell} key={i}>
                  <CheckmarkStatus value={paymentForGoodsAndServices}></CheckmarkStatus>
                </td>
              )}
            </tr>
            <tr className="tw-border-secondary-300 tw-border-b">
              <td className={styles.cell}>Cash withdrawals</td>
              {selectedCards.map(({ cashWithdrawals }, i) =>
                <td className={styles.cell} key={i}>
                  <CheckmarkStatus value={cashWithdrawals}></CheckmarkStatus>
                </td>
              )}
            </tr>
            <tr>
              <td className={styles.sectionTitle} colSpan={4}>
                Limits
              </td>
            </tr>
            <tr className="tw-border-secondary-300 tw-border-b">
              <td className={styles.cell}>Card Issuance Cost</td>
              {selectedCards.map(({ cardIssuanceCost }, i) =>
                <td className={styles.cell} key={i}>{cardIssuanceCost}</td>
              )}
            </tr>
            <tr className="tw-border-secondary-300 tw-border-b">
              <td className={styles.cell}>Card Top-Up Options</td>
              {selectedCards.map(({ cardTopUpOptions }, i) =>
                <td className={styles.cell} key={i}>{cardTopUpOptions}</td>
              )}
            </tr>
            <tr className="tw-border-secondary-300 tw-border-b">
              <td className={styles.cell}>Transaction Limit</td>
              {selectedCards.map(({ transactionLimit }, i) =>
                <td className={styles.cell} key={i}>{transactionLimit}</td>
              )}
            </tr>
            <tr className="tw-border-secondary-300 tw-border-b">
              <td className={styles.cell}>Daily Limit</td>
              {selectedCards.map(({ dailyLimit }, i) =>
                <td className={styles.cell} key={i}>{dailyLimit}</td>
              )}
            </tr>
            <tr>
              <td className={styles.cell}>Monthly Limit</td>
              {selectedCards.map(({ cardType, monthlyLimit, brandLogo }, i) =>
                <td className={styles.cell} key={i}>{monthlyLimit}</td>
              )}
            </tr>
            <tr>
              <td className={classNames(styles.cell, 'tw-py-0 tw-h-auto')}></td>
              {selectedCards.map(({ cardType, monthlyLimit, brandLogo }, i) => {
                const cardTier = getCardTier(cardType);
                return (
                  <td className={classNames(styles.cell, 'tw-py-0 tw-h-auto')} key={i}>
                    <div className="tw-flex tw-items-center tw-gap-3.5">
                      <Button
                        additional={{ isSoftGreen: true }}
                        onClick={() => handleLearnMoreClick(cardType)}
                        className={classNames(styles.learnMoreButton, 'btn-sm')}
                      >
                        Get my card
                      </Button>
                      <div className="tw-flex tw-flex-col tw-items-center tw-w-max tw-gap-1">
                        <img
                          src={`/images/${brandLogo.src}`}
                          alt={brandLogo.alt}
                          className={classNames(styles[`BrandLogo${logoCardClassMap[cardType]}`])}
                        />
                        {cardTier && <span className={styles.CardTier}>{cardTier}</span>}
                      </div>
                    </div>
                  </td>
                )
              })}
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </PageContainer>
  );
};

export default CardsComparePage;
