import { FC, ReactNode } from 'react';
import styles from './Card.module.scss';
import {
  CardType,
  CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeMastercardVirtual,
  CardTypeUnionpay,
  CardTypeVisaPhysical,
  CardTypeVisaVirtual,
  ICardBalanceDto,
  ICardDataActiveDto,
} from '../../../../../services/card-api';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import formatBalance from './../../../../../helpers/formatBalance.ts';
import { getCardTier } from './../../../../../helpers/card-tier.helper.ts';
import CopyValue from './../../../../../components/CopyValue';
import { formatCardNumber, maskCardNumber } from './../../../../../helpers/card-number.helper.ts';
import { cardKindString, isVirtualCard } from './../../../../../helpers/card-kind.helper.ts';
import { convertCentsToCurrency } from '../../../../../helpers/convert-cents-to-currency.helper.ts';
import { Box, SxProps } from '@mui/material';

interface CardProps {
  cardType: CardType;
  cardData?: ICardDataActiveDto | null;
  cardBalance?: ICardBalanceDto | null;
 
  // Shows other parts
  isShowBalance?: boolean;
  isShowKindCard?: boolean;
  isShowCardNumber?: boolean;
  isShowCvvAndDate?: boolean;
  isShowTier?: boolean;
  isShowCvv?: boolean;
  
  // Show additional features
  isLocked?: boolean;
  isMaskedData?: boolean;
  containerClassNames?: string;
  onClick?: () => void;
  children?: ReactNode;
  sx?: SxProps;
}

const logoByCardTypeMap: Record<CardType, string> = {
  [CardTypeVisaVirtual.VirtualVisaSilver]: '/images/visa-icon.svg',
  [CardTypeVisaVirtual.VirtualVisaGold]: '/images/visa-icon.svg',
  [CardTypeVisaVirtual.VirtualVisaPlatinum]: '/images/visa-icon.svg',
  [CardTypeVisaPhysical.PhysicalVisa]: '/images/visa-icon.svg',
  
  [CardTypeMastercardVirtual.VirtualMastercardSuperTransfer]: '/images/mastercard-icon.svg',
  [CardTypeMastercardVirtual.VirtualMastercardGlobalTransfer]: '/images/mastercard-icon.svg',
  [CardTypeMastercardVirtual.VirtualMastercardSilver]: '/images/mastercard-icon.svg',
  [CardTypeMastercardVirtual.VirtualMastercardGold]: '/images/mastercard-icon.svg',
  [CardTypeMastercardVirtual.VirtualMastercardPlatinum]: '/images/mastercard-icon.svg',
  [CardTypeMastercardPhysical.PhysicalMastercard]: '/images/mastercard-icon.svg',
  
  [CardTypeUnionpay.VirtualUnionpay]: '/images/unionpay-icon.svg',
  [CardTypeApplePay.VirtualApplePay]: '/images/mastercard-apple-pay-icon.svg',
};

const cardClassNamesByCardTypeMap: Record<CardType, string> = {
  [CardTypeVisaVirtual.VirtualVisaSilver]: 'Card_Visa',
  [CardTypeVisaVirtual.VirtualVisaGold]: 'Card_Visa',
  [CardTypeVisaVirtual.VirtualVisaPlatinum]: 'Card_Visa',
  [CardTypeVisaPhysical.PhysicalVisa]: 'Card_Visa',
  
  [CardTypeMastercardVirtual.VirtualMastercardSuperTransfer]: 'Card_VirtualMastercard',
  [CardTypeMastercardVirtual.VirtualMastercardGlobalTransfer]: 'Card_VirtualMastercard',
  [CardTypeMastercardVirtual.VirtualMastercardSilver]: 'Card_Mastercard',
  [CardTypeMastercardVirtual.VirtualMastercardGold]: 'Card_Mastercard',
  [CardTypeMastercardVirtual.VirtualMastercardPlatinum]: 'Card_Mastercard',
  [CardTypeMastercardPhysical.PhysicalMastercard]: 'Card_Mastercard',
  
  [CardTypeUnionpay.VirtualUnionpay]: 'Card_Unionpay',
  [CardTypeApplePay.VirtualApplePay]: 'Card_VisaApplePay',
};

export const Card: FC<CardProps> = observer(({
  cardType,
  cardData,
  cardBalance,
  isShowCardNumber = true,
  isShowCvvAndDate = true,
  isMaskedData = true,
  isShowBalance = true,
  isShowKindCard = false,
  isShowTier = true,
  isLocked = false,
  isShowCvv = false,
  onClick,
  children,
  containerClassNames,
  sx,
}) => {
  const getCardClass = () => {
    const classNames = [
      styles.Card,
      styles[cardClassNamesByCardTypeMap[cardType]],
      'Card',
      cardClassNamesByCardTypeMap[cardType]
    ];
    if (isLocked) {
      classNames.push(styles['Card_locked'], 'Card_locked');
    }
    return classNames;
  }

  const cardClass: string[] = getCardClass();
  const cardNumber = formatCardNumber(isMaskedData ? maskCardNumber(cardData?.number ?? '') : cardData?.number ?? '');
  
  return (
    <Box
      className={cn(styles.CardContainer, containerClassNames, 'CardContainer')}
      sx={sx}
      onClick={onClick}
    >
      <div className={cn(cardClass)}>
        <div className={cn(styles.CardHeader, 'CardHeader')}>
          <div className={cn(styles.CardHeaderPaymentInfo, 'CardHeaderPaymentInfo')}>
            <div className={styles.CardHeaderWrapperLogo}>
              <img
                src={logoByCardTypeMap[cardType]}
                className={cn(styles.CardHeaderPaymentLogo, 'CardHeaderPaymentLogo')}
                alt="Card brand logo"
              />
            </div>
            
            {isShowTier &&
              <span className={cn(styles.CardHeaderCardTier, 'CardHeaderCardTier')}>
                {cardType && getCardTier(cardType)}
              </span>
            }
          </div>
          <div className={cn(styles.CardHeaderTitle, 'CardHeaderTitle')}>
            {isShowBalance && cardBalance &&
                <>
                  {cardBalance?.originalCurrency} <span>
                    {formatBalance(convertCentsToCurrency(cardBalance?.amountInOriginalCurrency ?? 0))}
                  </span>
                </>
            }
            {isShowKindCard && cardKindString(cardType)}
          </div>
        </div>
        <div className={cn(
          styles.CardFooter,
          'CardFooter',
           isShowCvv && isVirtualCard(cardType) ? [styles.CardFooter_withCvv, 'CardFooter_withCvv'] : false,
        )}>
          {
            isShowCardNumber && <div>
              <div className={cn(styles.CardFooterItemLabel, 'CardFooterItemLabel', 'tw-uppercase')}>
                {isShowCvv && isVirtualCard(cardType) ? '' : 'CARD NUMBER'}
              </div>
              <div className={cn(styles.CardFooterItemData, styles.CardNumber, 'CardNumber CardFooterItemData')}>
                {
                  !!cardNumber.length
                    && <>{cardNumber}
                      {!isMaskedData &&
                        <CopyValue
                          value={cardData?.number ?? ''}
                          className={cn(styles.CopyIcon, 'CopyIcon', 'tw-p-0')}
                      ></CopyValue>}
                    </>
                }
              </div>
            </div>
          }
          {
           isShowCvvAndDate &&
              <div className={cn(styles.CardCvvDateContainer, 'CardCvvDateContainer')}>
                <div>
                  <div className={cn(styles.CardFooterItemLabel, 'CardFooterItemLabel')}>VALID THRU</div>
                  <div className={cn(styles.CardFooterItemData, styles.CardFooterDate, 'CardFooterItemData CardFooterDate')}>
                    {isMaskedData ? '••/••' : cardData?.expiryMonth + '/' + cardData?.expiryYear.slice(2, 4)}
                  </div>
                </div>
                {
                  isShowCvv && isVirtualCard(cardType) &&
                    <div className="FlexCentering">
                        <div className={cn(styles.CardFooterItemLabel, 'CardFooterItemLabel')}>CVV</div>
                        <div className={cn(styles.CardFooterItemData, 'CardFooterItemData')}>
                          {isMaskedData ? '•••' : cardData?.cvv}
                        </div>
                    </div>
                }
            </div>
          }
        </div>
      </div>
      {isLocked &&
          <div className={cn(styles.ReportUsAboutLocked, 'ReportUsAboutLocked')}>
            <img
              loading="lazy"
              src="/images/lock-in-green-container-icon.svg"
              className={cn(styles.ReportUsIcon, 'ReportUsIcon')}
              alt="Lock with green container"
            />
            <a className={cn(styles.ReportUsText, 'ReportUsText')} href={'mailto:support@vpluspay.hk'}>
              Report lost or stolen
            </a>
          </div>
      }
      {children}
    </Box>
  );
});
