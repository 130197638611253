import { SxProps } from '@mui/material';

export const cardSmSXProps: SxProps = {
  width: '350px',
  height: '206px',
  borderRadius: '10px',
  '.Card': {
    padding: '30px 32px 27px 32px',
  },
  '.CardHeaderPaymentLogo': {
    height: '25px',
    width: 'auto',
  },
  '.CardHeaderTitle': {
    fontSize: '22px',
    minHeight: '25px',
  },
  '.CardFooterItemLabel': {
    fontSize: '11px',
    minHeight: '14px',
    letterSpacing: '0.336px',
  },
  '.CardFooterItemData': {
    fontSize: '17px',
    minHeight: '20px',
    letterSpacing: '0.302px',
  },
  '.CardCvvDateContainer .CardFooterItemData': {
    letterSpacing: '0.134px',
  },
  '.CardFooter, .CardHeader': {
    gap: '10px',
  },
  '.CardHeaderPaymentInfo': {
    gap: '2px',
  },
  '.CardHeaderCardTier': {
    fontSize: '8px',
    minHeight: '10px',
    letterSpacing: '0.065px',
  },
  '.Card_Mastercard .CardHeaderPaymentLogo': {
    width: 'auto',
    height: '18px',
  },
  '.CardNumber': {
    gap: '6px',
  },
  '.CopyIcon svg': {
    width: 'auto',
    height: '12px',
  },
  '.ReportUsAboutLocked': {
    gap: '20px',
    '.ReportUsIcon': {
      width: 'auto',
      height: '45px',
    },
    '.ReportUsText': {
      fontSize: '15px',
    }
  },
}