import classNames from "classnames";
import React, { ReactNode, useState } from "react";
import { Navigate, NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./SecurityLayout.module.scss";
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../helpers/breakpoints.ts';
import { PageContainer } from '../../../../components/PageContainer/PageContainer.tsx';

/* Tab card component */
function TabCard(props: {
  topHeading: ReactNode,
  bottomHeading: ReactNode,
  isActive?: boolean,
  onClick?(): void
}) {
  /* DOM */
  return (
    <div
      className={
        classNames(
          styles.TabCard,
          {isActive: props.isActive},
          "tw-text-secondary"
        )}
      onClick={() => props.onClick?.()}
    >
      <div className={styles.tabCardTopHeading}>
        {props.topHeading}
      </div>
      <div className="tw-flex tw-justify-between tw-gap-4 tw-items-end">
        <div className={styles.tabCardBottomHeading}>
          {props.bottomHeading}
        </div>
        <div className={styles.tabCardCircle} />
      </div>
    </div>
  );
}

/* Component */
export default function SecurityLayout() {

  /* Hooks */
  const $navigate = useNavigate();
  const $location = useLocation();
  const breakpoint = useBreakpoint();
  const isDesktop = isBreakpointGreaterThan(breakpoint, Breakpoints.XL);

  /* Vars */
  const tab = $location.pathname.split("/").slice(-1)[0];
  if(!tab) return <Navigate to="/app/security" />;

  /* DOM */
  return (
    <PageContainer>
      <div className="regular-page">
        <div className="tw-flex tw-justify-between tw-items-center tw-gap-4">
          <div className="tw-text-1.5xl tw-font-semibold">Account security</div>
          {isDesktop && (
            <NavLink to="/app/profile" className="tw-font-semibold tw-underline">
              Back to Profile
            </NavLink>
          )}
        </div>

        <div className="tw-flex tw-flex-col tw-gap-4 tw-mt-7 xl:tw-flex-row xl:tw-gap-7 xl:tw-mt-6">
          <div className={classNames(styles.pageLeft, 'tw-flex tw-flex-col tw-gap-4 xl:tw-gap-5')}>
            <TabCard
              topHeading="Security level 1"
              bottomHeading="Password"
              isActive={tab === "changePassword"}
              onClick={() => $navigate("/app/security/changePassword")}
            />

            <TabCard
              topHeading="Security level 2"
              bottomHeading="Two-Factor Authentication"
              isActive={tab === "twoFa"}
              onClick={() => $navigate("/app/security/twoFa")}
            />
          </div>
          <div className={isDesktop ? styles.DesktopOutletContainer : ''}>
            <Outlet/>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
