import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import getMe from "../../controllers/users/getMe.ts";
import apiClient from "../../helpers/apiClient.ts";
import onError from "../../helpers/onError.ts";
import AppProfilePage from "../../pages/app/AppProfilePage/ProfilePage.tsx";
import AppChangePasswordPage from "../../pages/app/security/ChangePasswordPage/ChangePasswordPage.tsx";
import SecurityLayout from "../../pages/app/security/SecurityLayout/SecurityLayout.tsx";
import AppTwoFaPage from "../../pages/app/security/TwoFaPage/TwoFa.tsx";
import LandingPage from "../../pages/LandingPage/Landing.tsx";
import LoginPage from "../../pages/LoginPage/Login.tsx";
import Footer from "../Footer/Footer.tsx";
import Header from "../Header";
import styles from "./index.module.css";

import mainStore from "../../store/main.ts";
import AboutPage from "../../pages/AboutPage/index.tsx";
import PrivacyPolicyPage from "../../pages/PrivacyPolicyPage/index.tsx";
import TermsOfUsePage from "../../pages/TermsOfUsePage/index.tsx";
import AMLPage from "../../pages/AMLPage/index.tsx";
import MyCardsPage from '../../pages/app/Cards/MyCards/MyCards.tsx';
import GetMyCardPage from '../../pages/app/Cards/GetMyCardPage/GetMyCardPage.tsx';
import CardsComparePage from '../../pages/app/Cards/CompareCardsPage';
import KYCForm from '../../pages/app/Cards/CardKYC/KYCForm.tsx';
import { CardTransactionsPage } from '../../pages/app/Cards/CardTransactionsPage/CardTransactionsPage.tsx';
import { ToastContainer } from 'react-toastify';
import { TopUpCard } from '../../pages/app/Cards/MyCards/TopUpCard/TopUpCard.tsx';
import { MyCardsPageGuard } from '../../pages/app/Cards/MyCards/guards/MyCardsPageGuard.tsx';
import { PaymentForVirtualCard } from '../../pages/app/Cards/PaymentForVirtualCard/PaymentForVirtualCard.tsx';
import { PaymentForPhysicalCard } from '../../pages/app/Cards/PaymentForPhysicalCard/PaymentForPhysicalCard.tsx';
import { BurgerMenu } from '../BurgerMenu/BurgerMenu.tsx';
import { burgerMenuStore } from '../BurgerMenu/BurgerMenu.store.ts';

function App() {

  /* Hooks */
  const $navigate = useNavigate();
  const $location = useLocation();

  /* State */
  const [isPageReady, setIsPageReady] = useState<boolean>(false);

  /* Watch user session token */
  useEffect(() => {

    Promise.resolve()
      .then(() => {
        if (mainStore.sessionToken) {
          apiClient.defaults.headers["Authorization"] = `Bearer ${mainStore.sessionToken}`;

          return getMe();
        }
      })
      .then(() => {
        setIsPageReady(true);
      })
      .catch(onError);

  }, [mainStore.sessionToken]);

  /* Scroll page to top when routing */
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    burgerMenuStore.closeBurgerMenu();
  }, [$location.pathname]);

  /* If page is not ready, don't render */
  if (!isPageReady) return null;

  /* DOM */
  return (
    <div className={styles.App}>

      {/* Header */}
      {$location.pathname.startsWith('/app') || $location.pathname.startsWith('/login') ? (
        <Header />
      ) : null}

      {/* Main */}
      <main className="tw-flex-1">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route
            path="/*"
            element={
              <div className="tw-flex tw-flex-col tw-h-full">
                <Routes>
                  <Route path="/privacy" element={<PrivacyPolicyPage/>}/>
                  <Route path="/terms" element={<TermsOfUsePage/>}/>
                  <Route path="/aml" element={<AMLPage/>}/>
                  <Route path="/login" element={<LoginPage/>}/>

                  {/* App pages */}
                  {mainStore.sessionToken && mainStore.user ? (
                    <Route path="/app">
                      <Route path="profile" element={<AppProfilePage/>}/>
                      <Route path="security" element={<SecurityLayout/>}>
                        <Route path="changePassword" element={<AppChangePasswordPage/>}/>
                        <Route path="twoFa" element={<AppTwoFaPage/>}/>
                        <Route path="" element={<Navigate to="./changePassword" replace/>}/>
                      </Route>
                      <Route path="cards">
                        <Route path="" element={<MyCardsPageGuard><MyCardsPage/></MyCardsPageGuard>}/>
                        <Route path="get-my-card" element={<GetMyCardPage/>}/>
                        <Route path="compare" element={<CardsComparePage/>}/>
                        <Route path="kyc/:cardType" element={<KYCForm/>}/>
                        <Route path=":cardId/transactions" element={<CardTransactionsPage/>}/>
                        <Route path=":cardId/top-up" element={<TopUpCard/>}/>
                        <Route path=":cardId/payment-for-virtual-card" element={<PaymentForVirtualCard/>}/>
                        <Route path=":cardId/payment-for-physical-card" element={<PaymentForPhysicalCard/>}/>
                      </Route>
                    </Route>
                  ) : (
                    <Route path="*" element={<Navigate to="/login" replace/>}/>
                  )}

                  {/* 404 */}
                  <Route path="*" element={<Navigate to="/" replace/>}/>
                </Routes>
              </div>
            }
          />
        </Routes>
      </main>
      
      {/* Footer */}
      <Footer/>
      
      {/* Notifications wrapper */}
      <div className={styles.notificationsWrapper}>
        
        {mainStore.notifications.map((notification) => (
          <div key={notification.id} className={styles.notification}>
            <div className={styles.notificationTitle}>{notification.title}</div>
            <div className={styles.notificationContents}>{notification.contents}</div>
          </div>
        ))}
      </div>
      <ToastContainer/>
      <BurgerMenu
        open={burgerMenuStore.isBurgerMenuOpen}
        onClose={() => burgerMenuStore.closeBurgerMenu()}
      ></BurgerMenu>
    </div>
  );
}

export default observer(App);
