import { Box, styled } from '@mui/material';

export const SliderCard = styled(Box)(() => ({
  '.CardOverlay:not(.CardOverlay_sm)': {
    padding: '23px 24px 28px 33px',
    '.CardOverlayBadge': {
      width: '56px',
      height: '46px',
    },
    '.CardOverlayHeaderLink': {
      fontSize: '10px',
    },
    '.NextBtnLink': {
      width: '34px',
      height: '34px',
      borderRadius: '7px',
      'svg': {
        width: '16px',
      }
    },
    '.CardInformationTitle': {
      fontSize: '17px',
    },
    '.CardInformationDescription': {
      fontSize: '11px',
      marginTop: 0,
      '&:not(.CardInformationDescription_withBtnLink)': {
        marginTop: '7px',
      }
    },
  },
}));