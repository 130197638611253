import classNames from 'classnames';
import styles from '../../KYCForm.module.scss';
import { limitDocumentSizeMb } from '../../consts/consts.ts';

export const FileLimitationInfo = ({ className }: { className?: string }) => {
  return (
    <div className={classNames('tw-flex tw-gap-4.5 tw-bg-secondary-75 tw-rounded-1.5xl tw-p-5', className)}>
      <img
        src="/images/information-icon-with-red-container.svg"
        alt="Alert icon"
        className="tw-w-7.5 tw-h-6.5"
      />
      <span
        className={classNames(styles['AlertMessage-Text'], 'tw-text-sm tw-mt-1.5')}>
        Please be aware that uploaded files should not exceed {limitDocumentSizeMb} MB.
      </span>
    </div>
  )
}