import { forwardRef } from 'react';
import KYCStepOne from './../../../../Cards/CardKYC/components/KYCMCPhysical/components/KYCStepOne/KYCStepOne.tsx';
import KYCStepTwo from './../../../../Cards/CardKYC/components/KYCMCPhysical/components/KYCStepTwo/KYCStepTwo.tsx';
import KYCStepThree from './../../../../Cards/CardKYC/components/KYCMCPhysical/components/KYCStepThree/KYCStepThree.tsx';
import { useNavigate } from 'react-router-dom';

interface IKYCMCPhysical {
  step: number,
  onNextStepClick: () => void,
  formState: any,
  setFormState: any,
  isLoading?: boolean,
}

export const KYCMCPhysical = forwardRef<any, IKYCMCPhysical>(({
  step,
  onNextStepClick,
  formState,
  setFormState,
  isLoading,
}, ref) => {
  const navigate = useNavigate();
  return (
    <>
      {step === 1 && (
        <KYCStepOne
          ref={ref}
          setFormState={setFormState}
          onNextStepClick={onNextStepClick}
          formState={formState}
        />
      )}
      {step === 2 && (
        <KYCStepTwo
          ref={ref}
          onNextStepClick={onNextStepClick}
          formState={[formState, setFormState]}
        />
      )}
      {step === 3 && (
        <KYCStepThree
          ref={ref}
          onNextStepClick={onNextStepClick}
          formState={[formState, setFormState]}
          isLoading={isLoading}
        />
      )}
    </>
  )
});