import classNames from 'classnames';
import React, { FormEvent, useCallback, useImperativeHandle, useRef, useState, forwardRef } from 'react';
import styles from './KYCStepTwo.module.scss';
import { incomeOptions } from './../../../../consts/income-options.ts';
import { occupationOptions } from './../../../../consts/occupation-options.ts';
import { positionOptions } from './../../../../consts/position-options.ts';
import { showDangerToast } from './../../../../../../../../helpers/toasts.helper.ts';
import { ActionBtn } from '../../../../../../../../components/ActionBtn/ActionBtn.tsx';
import Select2 from '../../../../../../../../components/Select2';
import sharedStyles from '../../../kyc-shared-styles.module.scss';

// @ts-ignore
const KYCStepTwo = forwardRef<any, any>(({ onNextStepClick, formState }, ref) => {
  const nextButtonRef = useRef<HTMLButtonElement>();
  const [data, setFieldValue] = formState;

  const [annualIncome, setAnnualIncome] = useState(
    data.annualIncome || '5000-25000'
  );

  const setNestedFieldValue = (key: string, value: any) => {
    const keys = key.split('.');
    let currentLevel = formState[0];

    for (let i = 0; i < keys.length - 1; i++) {
      if (!currentLevel[keys[i]]) currentLevel[keys[i]] = {};
      currentLevel = currentLevel[keys[i]];
    }

    currentLevel[keys[keys.length - 1]] = value;
    setFieldValue({ ...formState[0] });
  };

  const handleIncomeSelect = (value: string) => {
    setFieldValue(value, 'annualIncome');
    setAnnualIncome(value);
  };
  
  const handleClickNextStep = useCallback(() => {
    if (nextButtonRef.current) {
      nextButtonRef.current.click();
    }
  }, []);
  
  useImperativeHandle(ref, () => ({
    nextStepClick() {
      handleClickNextStep();
    },
  }));

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    
    if (!data.annualIncome) {
      showDangerToast('Please select annual income');
      return;
    }
    if (!data.position) {
      showDangerToast('Please select position');
      return;
    }
    
    if (!data.occupation) {
      showDangerToast('Please select occupation');
      return;
    }
    
    onNextStepClick();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classNames(sharedStyles.kycContainer, styles.kycContainer)}>
        <div className={sharedStyles.kycFormSection}>
          <div>
            <h2 className={styles.FinancialInformationTitle}>Financial Information:</h2>
          </div>
          <div className={classNames(sharedStyles.kycGrid, 'tw-mt-8 xl:tw-mt-0 tw-flex tw-flex-col')}>
            <div>
              <label className="tw-text-sm tw-text-secondary">Annual Income (HKD)</label>
              <div className={classNames(sharedStyles.IncomeButtons, 'tw-mt-4 xl:tw-mt-4.5')}>
                {incomeOptions.map((option) => (
                  <button
                    key={option.value}
                    type="button"
                    className={classNames(sharedStyles.IncomeButton, {
                      [sharedStyles['IncomeButton_selected']]: annualIncome === option.value
                    })}
                    onClick={() => handleIncomeSelect(option.value)}>
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
            <Select2
              label="Occupation"
              options={occupationOptions}
              selected={occupationOptions.find(
                (opt) => opt.value === data.occupation
              )}
              onSelect={(option) =>
                setNestedFieldValue('occupation', option.value)
              }
              disabled
              required
            />
            <Select2
              label="Position"
              options={positionOptions}
              selected={positionOptions.find(
                (opt) => opt.value === data.position
              )}
              onSelect={(option) =>
                setNestedFieldValue('position', option.value)
              }
              required
              disabled
            />
          </div>
        </div>
        <div className={classNames(sharedStyles.kycFormSection, 'tw-hidden xl:tw-block')}>
          <div></div>
          <div className={classNames(sharedStyles.ActionBtn)}>
            <ActionBtn
              ref={nextButtonRef}
              className="tw-text-secondary tw-ml-auto"
              text="Next Step"
              suffixIcon="custom-arrow-1"
            />
          </div>
        </div>
      </div>
      <div className={classNames(sharedStyles.ActionBtn, 'tw-block xl:tw-hidden')}>
        <ActionBtn
          ref={nextButtonRef}
          className="tw-ml-auto"
          text="Next Step"
          suffixIcon="custom-arrow-1"
        />
      </div>
    </form>
  );
});

export default KYCStepTwo;
