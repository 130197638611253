import React, { FormEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import Icon from './../../../../../../../../components/Icon';
import TextInput from './../../../../../../../../components/TextInput';
import styles from './KYCStepOne.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import { showDangerToast } from './../../../../../../../../helpers/toasts.helper.ts';
import { documentTypeOptions } from './../../../../consts/document-type-options.ts';
import { phoneCodeOptions } from '../../../../../../../../consts/phone-code-options.ts';
import Select2, { SelectOptionModel } from '../../../../../../../../components/Select2';
import { ActionBtn }  from '../../../../../../../../components/ActionBtn/ActionBtn.tsx';
import validate from '../../../../../../../../helpers/validate.ts';
import { cardKYCCountriesStore } from '../../../../../../../../store/card-kyc-countries.store.ts';
import { getFileSize } from '../../../../../../../../helpers/file-size.ts';
import { exceedMaxFileSizeMb, limitDocumentSizeMb } from '../../../../consts/consts.ts';
import sharedStyles from '../../../kyc-shared-styles.module.scss';
import { FileLimitationInfo } from '../../../FileLimitationInfo/FileLimitationInfo.tsx';

type DocumentFieldKey = 'identificationDocument.image'
  | 'identificationDocument.faceImage'
  | 'identificationDocument.backImage';

const documentTitleMap: Record<DocumentFieldKey, string> = {
  'identificationDocument.image': 'Passport',
  'identificationDocument.faceImage': 'ID Front side',
  'identificationDocument.backImage': 'ID Back side',
};

// @ts-ignore
const KYCStepOne = observer(forwardRef<any, any>(({ onNextStepClick, formState, setFormState }, ref) => {
  const nextButtonRef = useRef<HTMLButtonElement>();
  const [documentsForLoadMap, setDocumentsForLoadMap] = useState<Record<DocumentFieldKey, boolean>>({
    'identificationDocument.image': true,
    'identificationDocument.faceImage': false,
    'identificationDocument.backImage': false,
  });
  const [countryOptions, setCountryOptions] = useState<SelectOptionModel<string>[]>([]);
  
  const checkFillFieldDocument = useCallback((fieldName: string) => {
    switch (fieldName) {
      case 'identificationDocument.backImage':
        return !!formState.identificationDocument.backImage;
      case 'identificationDocument.image':
        return !!formState.identificationDocument.image;
      case 'identificationDocument.faceImage':
        return !!formState.identificationDocument.faceImage;
      default:
        return false;
    }
  }, [formState]);
  
  useEffect(() => {
    setCountryOptions(cardKYCCountriesStore.countries.map(({ id, name: label }) => ({ value: id, label })));
  }, [cardKYCCountriesStore.countries]);
  
  useEffect(() => {
    cardKYCCountriesStore.getCountries();
  }, []);
  
  useEffect(() => {
    const newDocumentsForLoadMap = { ...documentsForLoadMap };
    if (formState.identificationDocument.type === 'PASSPORT') {
      newDocumentsForLoadMap['identificationDocument.backImage'] = false;
      newDocumentsForLoadMap['identificationDocument.faceImage'] = false;
      newDocumentsForLoadMap['identificationDocument.image'] = true;
      setNestedFieldValue('identificationDocument.backImage', null);
      setNestedFieldValue('identificationDocument.faceImage', null);
    } else {
      newDocumentsForLoadMap['identificationDocument.backImage'] = true;
      newDocumentsForLoadMap['identificationDocument.faceImage'] = true;
      newDocumentsForLoadMap['identificationDocument.image'] = false;
      setNestedFieldValue('identificationDocument.image', null);
    }
    setDocumentsForLoadMap(newDocumentsForLoadMap);
  }, [formState.identificationDocument.type]);
  
  const handleClickNextStep = useCallback(() => {
    if (nextButtonRef.current) {
      nextButtonRef.current.click();
    }
  }, []);
  
  useImperativeHandle(ref, () => ({
    nextStepClick() {
      handleClickNextStep();
    },
  }));
  
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    
    const checkIsValidEmail: true | string = validate('email', formState.email);
    
    if (typeof checkIsValidEmail === 'string') {
      showDangerToast(checkIsValidEmail);
      return;
    }
    
    const checkValidBirthday: true | string = validate(
      'dateOfBirth',
      new Date(formState.identificationDocument.birthday),
      'Date Of Birth',
    );
    if (typeof checkValidBirthday === 'string') {
      showDangerToast(checkValidBirthday);
      return;
    }
    
    if (!formState.countryCode) {
      showDangerToast('Please select country code');
      return;
    }
    
    if (!formState.identificationDocument.country) {
      showDangerToast('Please select country');
      return;
    }
    
    if (new Date(formState.identificationDocument.issuanceDate) > new Date(formState.identificationDocument.expiryDate)) {
      showDangerToast('Issuance Date date must be earlier than Expire date');
      return;
    }
    
    const checkIssuanceDate: true | string = validate(
      'pastDate',
      new Date(formState.identificationDocument.issuanceDate),
      'Issuance Date',
    );
    if (typeof checkIssuanceDate === 'string') {
      showDangerToast(checkIssuanceDate);
      return;
    }
    
    const checkExpireDate: true | string = validate(
      'futureDate',
      new Date(formState.identificationDocument.expiryDate),
      'Expire Date',
    );
    if (typeof checkExpireDate === 'string') {
      showDangerToast(checkExpireDate);
      return;
    }
    
    const requiredDocuments = [];
      if (formState.identificationDocument.type === 'PASSPORT') {
        requiredDocuments.push('identificationDocument.image');
      } else {
        requiredDocuments.push('identificationDocument.faceImage', 'identificationDocument.backImage')
      }
      
      if (requiredDocuments.some(d => !checkFillFieldDocument(d))) {
        showDangerToast('Please upload all documents.');
        return;
      }
      
      onNextStepClick();
    };
  
  const setNestedFieldValue = (key: string, value: any) => {
    const keys = key.split('.');
    let currentLevel = formState;
    if (keys.length === 1) {
      setFormState(value, key);
    } else {
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentLevel[keys[i]]) currentLevel[keys[i]] = {};
        currentLevel = currentLevel[keys[i]];
      }
      currentLevel[keys[keys.length - 1]] = value;
      setFormState({ ...formState });
    }
  };
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: DocumentFieldKey) => {
    const file = event.target.files?.[0];
    if (file) {
      setNestedFieldValue(fieldName, '');
      if (getFileSize(file, 'mb') > limitDocumentSizeMb) {
        showDangerToast(exceedMaxFileSizeMb.replace('{{maxLimit}}', limitDocumentSizeMb.toString()));
        return;
      }
      const reader = new FileReader();
      reader.onload = function (e) {
        const base64 = e.target?.result;
        if (base64) {
          setNestedFieldValue(fieldName, base64.toString());
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={sharedStyles.kycContainer}>
        <div className={sharedStyles.kycFormSection}>
          <div>
            <h2 className={sharedStyles.PersonalInformationTitle}>Personal Information:</h2>
          </div>
          <div className="tw-mt-8 xl:tw-mt-0">
            <div className={sharedStyles.kycGrid}>
              <TextInput
                type="text"
                name="firstNameEn"
                label="First Name"
                minLength={2}
                value={formState.firstNameEn}
                onChange={setFormState}
                required
              />
              <TextInput
                type="text"
                name="lastNameEn"
                label="Last Name"
                minLength={2}
                value={formState.lastNameEn}
                onChange={setFormState}
                required
              />
              <TextInput
                type="email"
                name="email"
                label="Email"
                minLength={5}
                value={formState.email}
                onChange={setFormState}
                required
              />
              <TextInput
                type="date"
                name="identificationDocument.birthday"
                label="Date of Birth"
                value={formState.identificationDocument.birthday}
                onChange={setFormState}
                required
              />
              <div className={sharedStyles.phoneRow}>
                <Select2
                  label="Country code"
                  options={phoneCodeOptions}
                  selected={phoneCodeOptions.find(
                    (opt) => opt.value === formState.countryCode
                  )}
                  onSelect={(option) =>
                    setNestedFieldValue('countryCode', option.value)
                  }
                  required
                />
                <TextInput
                  type="text"
                  name="phone"
                  label="Phone Number"
                  value={formState.phone}
                  onChange={setFormState}
                  required
                />
              </div>
              <Select2
                label="Country"
                options={countryOptions}
                selected={countryOptions.find(
                  (opt) => opt.value === formState.identificationDocument.country
                )}
                onSelect={(option) =>
                  setNestedFieldValue(
                    'identificationDocument.country',
                    option.value
                  )
                }
                required
              />
              <Select2
                label="Type of Document"
                options={documentTypeOptions}
                selected={documentTypeOptions.find(
                  (opt) => opt.value === formState.identificationDocument.type
                )}
                onSelect={(option) =>
                  setNestedFieldValue('identificationDocument.type', option.value)
                }
                required
              />
              <TextInput
                type="text"
                name="identificationDocument.number"
                label="Document Series and Number"
                minLength={3}
                value={formState.identificationDocument.number}
                onChange={setFormState}
                required
              />
              <TextInput
                type="date"
                name="identificationDocument.issuanceDate"
                label="Issuance Date"
                value={formState.identificationDocument.issuanceDate}
                onChange={setFormState}
                required
              />
              <TextInput
                type="date"
                name="identificationDocument.expiryDate"
                label="Expiry Date"
                value={formState.identificationDocument.expiryDate}
                onChange={setFormState}
                required
              />
            </div>
          </div>
        </div>

        <div className={classNames(sharedStyles.kycFormSection, 'tw-mt-8.5 xl:tw-mt-14')}>
          <div>
            <h2>Documents:</h2>
          </div>
          <div className={classNames(sharedStyles.documentsContainer, 'tw-mt-4 xl:tw-mt-0')}>
            {Object.entries(documentsForLoadMap).filter(([_, value]) => value).map(([fieldName, _]) => (
              <button
                type="button"
                key={fieldName}
                className={classNames(
                  sharedStyles.UploadBlock,
                  { [sharedStyles['UploadBlock_loadedFile']]: checkFillFieldDocument(fieldName as DocumentFieldKey) })
                }
                onClick={() => document.getElementById(`${fieldName}`)?.click()}
              >
                <div className={sharedStyles['UploadBlock-Header']}>
                  <Icon icon="custom-documents-upload" className={sharedStyles['UploadBlock-Icon']}/>
                  <span className={sharedStyles['UploadBlock-Text']}>
                    {checkFillFieldDocument(fieldName as DocumentFieldKey) ? 'Loaded' : 'Download'}
                  </span>
                </div>
                <p className={sharedStyles['UploadBlock-Title']}>{documentTitleMap[fieldName as DocumentFieldKey]}</p>
                <input
                  name={`${fieldName}`}
                  type="file"
                  id={`${fieldName}`}
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, fieldName)}
                  style={{ display: 'none' }}
                />
              </button>
            ))}
          </div>
        </div>

        <ActionBtn
          ref={nextButtonRef}
          className={classNames(sharedStyles.ActionBtn, 'tw-text-secondary tw-ml-auto tw-hidden xl:tw-flex')}
          text="Next Step"
          suffixIcon="custom-arrow-1"
        />
      </div>
      <FileLimitationInfo className="tw-block tw-mt-5 xl:tw-hidden"></FileLimitationInfo>
      <ActionBtn
        ref={nextButtonRef}
        className={classNames(sharedStyles.ActionBtn, 'tw-ml-auto tw-flex xl:tw-hidden')}
        text="Next Step"
        suffixIcon="custom-arrow-1"
      />
    </form>
  );
}));

export default KYCStepOne;
