import { SxProps } from '@mui/material';

export const cardBgMobileSxProps: SxProps = {
  '.CardInformationDescription': {
    fontSize: '10px',
  },
  '.CardInformationTitle': {
    fontSize: '15px',
  },
  '.CardOverlayBadge': {
    width: 48,
      height: 40,
  },
  '.CardOverlay': {
    padding: '20px 28px 25px'
  },
};