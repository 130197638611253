import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormState } from './../../../../hooks/useFormState';
import { KYCMCPhysical } from './../../Cards/CardKYC/components/KYCMCPhysical/KYCMCPhysical.tsx';
import { KYCVisaPhysical } from './../../Cards/CardKYC/components/KYCVisaPhysical/KYCVisaPhysical.tsx';
import {
  CardType,
  CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeUnionpay,
  CardTypeVisaPhysical
} from '../../../../services/card-api/interfaces';
import styles from './KYCForm.module.scss';
import { ActionBtn } from '../../../../components/ActionBtn/ActionBtn.tsx';
import classNames from 'classnames';
import { cardKycApiService } from '../../../../services/card-kyc-api';
import { isPhysicalCard } from '../../../../helpers/card-kind.helper.ts';
import { PageContainer } from '../../../../components/PageContainer/PageContainer.tsx';
import { FileLimitationInfo } from './components/FileLimitationInfo/FileLimitationInfo.tsx';
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../helpers/breakpoints.ts';
import { actionBtnSmallSx } from './action-btn-small-sx.ts';

const KYCForm: FC = () => {
  const childRef = useRef<any>();
  const { cardType } = useParams();
  const [isSendingForm, setIsSendingForm] = useState<boolean>(false);
  const navigate = useNavigate();
  const [countStep, setCountStep] = useState(1);
  const breakpoint = useBreakpoint();
  const [formState, setFormState] = useFormState({
    firstNameEn: '',
    firstName: '',
    lastNameEn: '',
    lastName: '',
    countryCode: '',
    phone: '',
    email: '',
    cardType: '',
    address: {
      country: '',
      city: '',
      postalCode: '',
      line1: '',
      line2: '',
      line3: ''
    },
    annualIncome: '',
    occupation: 'Businessmen',
    position: 'senior management',
    identificationDocument: {
      number: '',
      issuanceDate: '',
      expiryDate: '',
      birthday: '',
      country: '',
      type: 'PASSPORT',
      image: '',
      birthCountry: '',
      residenceCountry: ''
    },
    signImage: '',
    addressImage: '',
    cardBin: ''
  });

  const isDesktop = isBreakpointGreaterThan(breakpoint, Breakpoints.XL);

  useEffect(() => {
    setFormState(cardType as CardType, 'cardType');
    switch (cardType as CardType) {
      case CardTypeMastercardPhysical.PhysicalMastercard:
        setCountStep(4);
        break;
      case CardTypeUnionpay.VirtualUnionpay:
        setCountStep(3);
        break;
      case CardTypeVisaPhysical.PhysicalVisa:
        setCountStep(3);
        break;
      case CardTypeApplePay.VirtualApplePay:
        setCountStep(2);
        break;
    }
  }, [cardType]);
  
  const [step, setStep] = useState(1);
  
  const handleClickForward = () => {
    if (childRef.current) childRef.current?.nextStepClick();
  };

  const handleNextStepClick = () => {
    if (step === countStep - 1) {
      if (!isSendingForm) submitForm();
    } else {
      setStep((prevStep) => Math.min(prevStep + 1, countStep));
    }
  };

  const handlePrevStepClick = useCallback(() => {
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  }, []);

  const submitForm = async () => {
    setIsSendingForm(true);
    try {
      const { cardId } = await cardKycApiService.sendCardKYC(formState as any);
      const sharedPath = `/app/cards/${cardId}`;
      navigate( isPhysicalCard(cardType as CardType)
        ? `${sharedPath}/payment-for-physical-card`
        : `${sharedPath}/payment-for-virtual-card`
      );
    } finally {
      setIsSendingForm(false);
    }
  };

  return (
    <PageContainer isRegular>
      <h1 className="tw-text-1.5xl">Questionnaire for opening a debit card</h1>
      <div className={classNames(styles.KYCIndividualPage, 'tw-mt-6')}>
        <div className={styles.progressBarWrapper}>
          <div className={styles.progressBarBlock}>
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-5 tw-text-sm xl:tw-text-base">
              <ActionBtn
                className={classNames(styles.stepBtn, 'BorderNone disabled:tw-opacity-100')}
                disabled={step === 1}
                prefixIcon="arrow-prev"
                text="Back"
                onClick={handlePrevStepClick}
                sx={!isDesktop ? {...actionBtnSmallSx } : null}
              />
              <ActionBtn
                className={classNames(styles.stepBtn, 'BorderNone disabled:tw-opacity-100')}
                disabled={step >= countStep}
                text="Forward"
                suffixIcon="arrow-next"
                onClick={handleClickForward}
                sx={!isDesktop ? {...actionBtnSmallSx } : null}
              />
            </div>
            <div className={classNames('tw-mt-7.5 xl:tw-mt-10')}>
              <span>Process of filling out the questionnaire</span>
              <div className={classNames(styles.progressBarProgress, 'tw-mt-2.5 xl:tw-mt-4')}>
                <div style={{ width: `${(100 / countStep) * step}%` }}></div>
              </div>
            </div>
          </div>
          { step === 1 && isDesktop && <FileLimitationInfo className="tw-mt-4"></FileLimitationInfo> }
        </div>

        {cardType === CardTypeMastercardPhysical.PhysicalMastercard && (
          <KYCMCPhysical
            ref={childRef}
            step={step}
            formState={formState}
            setFormState={setFormState}
            onNextStepClick={handleNextStepClick}
            isLoading={isSendingForm}
          />
        )}
        {cardType === CardTypeVisaPhysical.PhysicalVisa && (
          <KYCVisaPhysical
            ref={childRef}
            step={step}
            formState={formState}
            setFormState={setFormState}
            onNextStepClick={handleNextStepClick}
            isLoading={isSendingForm}
          />
        )}
      </div>
    </PageContainer>
  );
};

export default KYCForm;
