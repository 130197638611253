import classNames from "classnames";
import {observer} from "mobx-react-lite";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import logout from "../../../controllers/users/logout.ts";
import styles from "./ProfilePage.module.scss";

import mainStore from "../../../store/main.ts";
import { ProfileSummary } from '../components/ProfileSummary/ProfileSummary.tsx';
import { PageContainer } from '../../../components/PageContainer/PageContainer.tsx';

function AppProfilePage() {

  /* Hooks */
  const $navigate = useNavigate();

  /* If no user data, return null */
  if(!mainStore.user) return null;

  /* DOM */
  return (
    <PageContainer>
      <div className="regular-page">
        <div className={classNames(styles.ProfileInfo, 'tw-bg-secondary-75 tw-rounded-2xl')}>
          <div className="xl:tw-px-5">
            <ProfileSummary/>
          </div>
          <div className="tw-border-t tw-border-secondary-400 tw-my-4 xl:tw-mt-9.5 xl:tw-my-7.5"></div>
          <div className="tw-flex tw-items-center tw-gap-3 xl:tw-gap-7.5 tw-font-semibold xl:tw-px-5">
            <div className="tw-flex tw-flex-col tw-gap-3 tw-min-w-0 xl:tw-flex-row xl:tw-items-center xl:tw-gap-7.5">
              <div className="tw-flex tw-items-center tw-gap-4">
              <span className="tw-flex-centering tw-bg-black-700 tw-min-w-7.5 tw-h-6.25 tw-rounded-7px">
                <img src="/images/envelope.svg" alt="envelope" className="tw-h-3"/>
              </span>
                <span className="tw-truncate">{mainStore.user.email}</span>
              </div>
              <div className="tw-flex tw-items-center tw-gap-4 tw-font-semibold">
              <span className="tw-flex-centering tw-bg-black-700 tw-min-w-7.5 tw-h-6.25 tw-rounded-7px">
                <img src="/images/phone.svg" alt="phone" className="tw-h-3"/>
              </span>
                <span className="tw-truncate">{mainStore.user.phoneNumber}</span>
              </div>
            </div>

            <div className="tw-flex tw-flex-1 tw-justify-end tw-self-end xl:tw-self-center">
              <Button
                className="tw-gap-4 tw-h-max"
                additional={{ isZeroed: true }}
                onClick={() => logout()}
              >
              <span className="tw-flex-centering tw-bg-danger tw-w-6 tw-h-5 xl:tw-w-7.5 xl:tw-h-6.25 tw-rounded-7px">
                <img src="/images/output.svg" alt="output" className="tw-w-auto tw-h-2.5 xl:tw-h-3"/>
              </span>
                <span className="tw-hidden xl:tw-inline-block">Log Out</span>
              </Button>
            </div>
          </div>
        </div>

        <div className={
          classNames(
            styles.AdditionalBlocks,
            'tw-mt-4.5 tw-gap-4.5 xl:tw-gap-5 xl:tw-mt-7'
          )}>
          <div
            className={classNames(styles.AccountSecurity, 'tw-flex tw-items-center tw-bg-secondary-75 tw-relative tw-z-1')}>
            <div>
              <h5 className={styles.InfoTitle}>Account security</h5>
              <p className={classNames(styles.TextMessage, styles.InfoText, 'tw-mt-0.5 xl:tw-mt-2')}>
                Strengthen the protection of your VPlus account by enabling two-factor authentication (2FA).
              </p>
              <NavLink
                className={classNames(
                  styles.InfoBtn,
                  'btn-sm btn-soft-green tw-flex-centering tw-mt-2 xl:tw-mt-3'
                )}
                to={'/app/security'}
              >
                Security
              </NavLink>
            </div>
            <div className={styles.AccountSecurityImgWrapper}></div>
          </div>

          <div
            className={classNames(
              styles.ConfusedInfo,
              'tw-flex tw-items-center tw-bg-black-700 tw-text-white tw-relative tw-z-1'
            )}
          >
            <div>
              <h5 className={styles.InfoTitle}>Feeling lost or confused?</h5>
              <p className={classNames(styles.TextMessageConfused, styles.InfoText, 'tw-mt-0.5 xl:tw-mt-2')}>
                Our dedicated support team is just a message away!
              </p>
              <NavLink
                className={classNames(
                  styles.InfoBtn,
                  'btn-sm btn-soft-green tw-flex-centering tw-mt-2 xl:tw-mt-3'
                )}
                to={'mailto:support@vpluspay.hk'}
              >
                Support
              </NavLink>
            </div>
            <div className={styles.SupportLaptopImgWrapper}></div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default observer(AppProfilePage);
