import { FC } from 'react';
import styles from './ActionButton.module.scss';
import classNames from 'classnames';
import Icon from '../../../../../../components/Icon';
import { Box, SxProps } from '@mui/material';
import cn from 'classnames';

interface ActionButtonProps {
  icon: string;
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  pinNotSet?: boolean;
  sx?: SxProps;
}

const ActionButton: FC<ActionButtonProps> = ({ icon, text, onClick, disabled, pinNotSet, sx }) => {
  return (
    <Box className={classNames(styles.actionButtonWrapper, 'tw-relative tw-overflow-hidden')} sx={sx}>
      <button
        className={cn(styles.actionButton, 'actionButton')}
        disabled={disabled || pinNotSet}
        onClick={onClick}
      >
        <img loading="lazy" src={'/images/' + icon + '.svg'} className={styles.actionIcon} alt={icon + '-icon'}/>
        <span>{text}</span>
      </button>
      {pinNotSet &&
          <div className={classNames(
            styles.NoActiveCard,
            'tw-absolute tw-position-full tw-flex-centering tw-before-absolute',
          )}>
              <Icon icon={'lock'} className="tw-z-1 tw-size-8 xl:tw-size-11"></Icon>
          </div>
      }
    </Box>
  );
};

export default ActionButton;
