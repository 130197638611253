import {Link} from 'react-router-dom';
import styles from './index.module.css';
import { PageContainer } from '../../components/PageContainer/PageContainer.tsx';

export default function TermsOfUsePage() {

  /* DOM */
  return (
    <PageContainer>
      <div className={styles.TermsOfUsePage}>
        <div className={styles.container}>
          <Link to="/">
            <img
              className={styles.logo}
              src="/vplus-dark-full.svg"
              alt="logo"
            />
          </Link>
          <h2 className={styles.pageTitle}>Terms and Conditions</h2>

          <h2>Introduction</h2>
          <p>
            VPLUS GREATER BAY EXCHANGE LIMITED duly registered in Hong Kong at
            FLAT 2, 11/F, YUEN LONG TRADE CENTRE, 99-109 CASTLE PEAK ROAD, YUEN
            LONG, NEW TERRITORIES, Money transfer services are facilitated by
            Company under the authority of a LICENSE FOR THE OPERATION OF MONEY
            SERVICES (License No. 19-08-02842) issued by the CUSTOMS AND EXCISE
            DEPARTMENT. <br/>
            The website www.vpluspay.hk is the sole property of VPLUS GREATER BAY
            EXCHANGE LIMITED. <br/>
            By accessing this site, you agree to be bound by the terms and
            conditions set forth below and as modified from time to time by VPLUS
            GREATER BAY EXCHANGE LIMITED. <br/>
            <br/>
            Services <br/>
            VPLUS GREATER BAY EXCHANGE LIMITED holds a Money Service Operator
            (MSO) licence and can provide a range of financial services related to
            currency exchange and money transfers. <br/>
            The scope of services under the MSO licence includes: <br/>
            Money Exchange: Providing customers with currency exchange services,
            which include buying and selling foreign currencies. <br/>
            Money transfer services: Providing services to send money to other
            jurisdictions outside Hong Kong. This includes wire transfers and
            physical movement of funds through various channels. <br/>
            These services are regulated by the Hong Kong Customs and Excise
            Department, which enforces various anti-money laundering (AML) and
            counter-terrorist financing (CTF) requirements. <br/>
            The MSO licence allows the operator to provide these services legally
            and requires compliance with strict regulatory standards, including
            customer due diligence and record keeping. <br/>
          </p>

          <h2>
            Registration in the System and <br/> creation of an Account
          </h2>
          <p>
            In order to start using the services of VPLUS GREATER BAY EXCHANGE
            LIMITED, the Customer must register in the System. VPLUS GREATER BAY
            EXCHANGE LIMITED has the right to refuse to register a new Customer
            without giving any reason, but VPLUS GREATER BAY EXCHANGE LIMITED
            guarantees that the reason for refusing registration is always based
            on valid reasons, which VPLUS GREATER BAY EXCHANGE LIMITED is not
            obliged or authorised to disclose. <br/>
            During registration in the System, an Account is created for the
            Client. The Account is personal and only the owner, i.e. the Customer,
            is entitled to use it (connect to it). Once the Client has registered
            in the System and created an Account, a VPLUS GREATER BAY EXCHANGE
            LIMITED Account is automatically opened for the Client. <br/>
            The Customer may hold only one Account. <br/>
            VPLUS GREATER BAY EXCHANGE LIMITED provides services to persons who
            are at least 18 years of age. Persons under the age of 18 are not
            allowed to use the services of VPLUS GREATER BAY EXCHANGE LIMITED.{' '}
            <br/>
            The Client confirms that he/she provided accurate information about
            himself/herself during registration in the System and that in future,
            when changing or supplementing the data about himself/herself, he/she
            will provide only accurate information. The Client shall be liable for
            any losses incurred as a result of providing incorrect data. <br/>
            In the cases and manner provided for in the Agreement or the System,
            the Customer must confirm the Account and the provision of a new
            service or part of a service, and undergo Customer identification in
            order for VPLUS GREATER BAY EXCHANGE LIMITED to commence or continue
            providing services. Identification of the Customer and confirmation of
            the Account and provision of new services is necessary to protect the
            interests of the Customer and VPLUS GREATER BAY EXCHANGE LIMITED.{' '}
            <br/>
            VPLUS GREATER BAY EXCHANGE LIMITED has the right to request data
            and/or documents with which VPLUS GREATER BAY EXCHANGE LIMITED can
            identify the Customer and/or obtain important information about the
            Customer necessary for the proper provision of services. The specific
            data and/or documents to be provided will be indicated in the
            notification to the Customer of the need to undergo the Customer
            identification procedure or other confirmation procedure. <br/>
            In the course of Customer identification, VPLUS GREATER BAY EXCHANGE
            LIMITED has the right to require the Customer to provide the originals
            of the documents required by VPLUS GREATER BAY EXCHANGE LIMITED and/or
            copies thereof, and/or copies of documents certified by a notary
            public or other person authorised by the state. <br/>
            The locations, procedures, conditions and prices of the Client
            identification process are specified here. <br/>
            In individual cases, in fulfilment of statutory obligations, or when
            required by the type of documents requested (e.g. it is necessary to
            provide the original document), VPLUS GREATER BAY EXCHANGE LIMITED has
            the right to require the Client to undergo the identification process
            in a specific manner specified by VPLUS GREATER BAY EXCHANGE LIMITED
            (e.g. at a Customer Service Centre). <br/>
            The Parties agree that the Customer may confirm its documents (e.g.
            contracts, powers of attorney, etc.) by electronic means (including
            but not limited to a special pen (stylus) for on-screen signatures).{' '}
            <br/>
            VPLUS GREATER BAY EXCHANGE LIMITED shall have the right to request
            from the Customer additional information and/or documents related to
            the Customer or the transactions carried out by the Customer, and
            shall also have the right to suspend the Customer's transaction until
            the Customer provides additional information and/or documents related
            to the suspended transaction. In addition, VPLUS GREATER BAY EXCHANGE
            LIMITED shall have the right to require the Customer to complete and
            periodically update the details of the ‘Customer Questionnaire’. If
            the Customer fails to provide additional information and/or documents
            within a reasonable period of time set by VPLUS GREATER BAY EXCHANGE
            LIMITED, VPLUS GREATER BAY EXCHANGE LIMITED may terminate the
            provision of services to the Customer (in whole or in part). VPLUS
            GREATER BAY EXCHANGE LIMITED has the right to require that copies of
            documents provided be notarised and/or translated into English. All
            documents and information shall be prepared and provided at the
            Client's expense. <br/>
            The Customer shall be notified of the confirmation of the Account,
            commencement of a new service or resumption of a suspended service by
            e-mail (e-mail), the address of which was specified by the Customer
            during registration in the System, or by SMS message if the Customer
            specified only a mobile phone number during registration. <br/>
            If the Customer has provided incorrect data about himself/herself,
            he/she is obliged to correct it, and if the Customer has created
            several Accounts due to inaccurate data, the Customer is obliged to
            inform VPLUS GREATER BAY EXCHANGE LIMITED so that the created Accounts
            can be merged into one. In case of violation of the provisions of this
            clause, the Customer may be blocked, the transactions carried out by
            the Customer may be recognised as invalid and, if necessary, the data
            may be handed over to law enforcement authorities. <br/>
          </p>

          <h2>
            Opening a VPLUS GREATER BAY EXCHANGE LIMITED <br/> Account Methods of
            keeping the <br/> Client's funds in the Account.
          </h2>
          <p>
            Conditions for depositing and transferring funds <br/>
            On the basis of this Agreement, a VPLUS GREATER BAY EXCHANGE LIMITED
            Account shall be opened for the Customer in the System for an
            indefinite period of time. <br/>
            The VPLUS GREATER BAY EXCHANGE LIMITED Account allows Customers to
            deposit and transfer funds to their Account, as well as to keep funds
            intended for transfers, make local and international money transfers,
            make payments, receive funds to the Account, pay for goods and
            services and perform other operations directly related to money
            transfers. <br/>
            The Customer's funds in the VPLUS GREATER BAY EXCHANGE LIMITED Account
            may be held in the following manner - in the form of Electronic Money
            that VPLUS GREATER BAY EXCHANGE LIMITED issues after the Customer
            transfers or deposits funds into his/her VPLUS GREATER BAY EXCHANGE
            LIMITED Account. After the Customer has deposited, transferred or
            received funds into the Account, VPLUS GREATER BAY EXCHANGE LIMITED,
            upon receipt, credits these funds to the Customer's Account, thereby
            issuing Electronic Money at par value. <br/>
            The nominal value of the funds is the same as the nominal value of the
            funds transferred to the VPLUS GREATER BAY EXCHANGE LIMITED Account.{' '}
            <br/>
            The Customer may create and own several VPLUS GREATER BAY EXCHANGE
            LIMITED Accounts in the same Account, which he may use at his
            discretion. <br/>
            At the Customer's request, the funds held in the Customer's VPLUS
            GREATER BAY EXCHANGE LIMITED Account shall be withdrawn (redeemed) at
            any time at their nominal value (except as provided for in the
            Agreement in cases where the use of the Account is limited). <br/>
            The Customer expresses his wish to withdraw (redeem) funds by creating
            a Payment Order to transfer funds from his VPLUS GREATER BAY EXCHANGE
            LIMITED Account to any other account specified by the Customer (banks
            and electronic payment systems, to which VPLUS GREATER BAY EXCHANGE
            LIMITED can transfer money) or to return funds from their VPLUS
            GREATER BAY EXCHANGE LIMITED Account by other means specified in the
            System and supported by VPLUS GREATER BAY EXCHANGE LIMITED. VPLUS
            GREATER BAY EXCHANGE LIMITED has the right to apply restrictions on
            withdrawal (redemption) of funds if there are good reasons. <br/>
            There is no additional charge for withdrawal (redemption) of funds.
            When withdrawing (redeeming) funds, the Customer shall pay the usual
            Fee for a Payment Transfer or payment, the amount of which depends on
            the Payment Transaction performed by the Customer. The standard VPLUS
            GREATER BAY EXCHANGE LIMITED Fee for the transfer or disbursement of
            funds will be charged. <br/>
            If the Customer terminates the Agreement and requests to close the
            Account of VPLUS GREATER BAY EXCHANGE LIMITED and delete the Account
            in the System, or if, in the cases provided for in the Agreement,
            VPLUS GREATER BAY EXCHANGE LIMITED ceases to provide the
            Account-related services to the Customer and deletes the Customer's
            Account from the System, the funds in the Account shall be transferred
            to the Customer's bank account or electronic payment account in
            another system specified by the Customer. VPLUS GREATER BAY EXCHANGE
            LIMITED shall be entitled to deduct from such refunds the funds
            belonging to VPLUS GREATER BAY EXCHANGE LIMITED (the Fee for the
            services provided, but not paid by the Customer, as well as expenses,
            including but not limited to fines, imposed by International Payment
            Card Organisations, other financial institutions and/or governmental
            agencies, and compensation for losses incurred by VPLUS GREATER BAY
            EXCHANGE LIMITED as a result of the Client's breach of this
            Agreement). In the event of a dispute between VPLUS GREATER BAY
            EXCHANGE LIMITED and the Client, VPLUS GREATER BAY EXCHANGE LIMITED
            has the right to withhold the money that is the subject of the dispute
            until the dispute is resolved. <br/>
            If it is not possible to return the money to the Client for reasons
            beyond the control of VPLUS GREATER BAY EXCHANGE LIMITED, the Client
            shall be notified immediately and shall be obliged to immediately
            indicate another account or provide additional information necessary
            for the return of the money (making the payment). <br/>
            <br/>
            The moment of receipt of the Payment Order, requirements to the
            Payment Order and refusal to execute the Payment Order <br/>
            When the Customer is a Payer, the Payment Order shall be deemed to
            have been received by VPLUS GREATER BAY EXCHANGE LIMITE (the Payment
            Order execution term starts) on the day of its receipt, and if the
            moment of receipt of the Payment Order does not coincide with a VPLUS
            GREATER BAY EXCHANGE LIMITE business day, the Payment Order shall be
            deemed to have been received on the nearest business day. <br/>A
            Payment Order which has been received by VPLUS GREATER BAY EXCHANGE
            LIMITE on a working day of VPLUS GREATER BAY EXCHANGE LIMITE, but not
            during the established working hours, shall be deemed to have been
            received on the nearest working day. <br/>
            VPLUS GREATER BAY EXCHANGE LIMITE shall be entitled to keep any
            Payment Orders provided in any manner agreed with VPLUS GREATER BAY
            EXCHANGE LIMITE, as well as information on all Payment Transactions
            that have been carried out by the Customer or on the Customer's
            Payment Order. VPLUS GREATER BAY EXCHANGE LIMITE may provide the data
            referred to in this clause to the Customer and/or third parties who
            have legal grounds to obtain such data as evidence of the provision of
            Payment Orders and/or Payment Transactions. <br/>
            The Payment Orders provided by the Client must comply with the
            requirements for the provision of the Payment Order and/or the content
            of the Payment Order established by law and VPLUS GREATER BAY EXCHANGE
            LIMITE. Payment Orders submitted by the Customer shall be clearly and
            unambiguously formulated, shall be executable and shall clearly
            express the Customer's will. VPLUS GREATER BAY EXCHANGE LIMITE is not
            responsible for errors, inconsistencies, repetitions and/or
            contradictions in the Customer's Payment Orders, including but not
            limited to the correctness of the Customer's Payment Order details. If
            the Payment Order provided by the Customer does not contain sufficient
            data or is otherwise deficient, VPLUS GREATER BAY EXCHANGE LIMITE,
            depending on the nature of the deficiencies in the Payment Order, may
            refuse to execute such Payment Order or execute it according to the
            data specified in the Payment Order. <br/>
            VPLUS GREATER BAY EXCHANGE LIMITE may refuse to execute a Payment
            Order if there are reasonable doubts as to whether the Payment Order
            has been submitted by the Customer's legal representative, as to
            whether the documents submitted to VPLUS GREATER BAY EXCHANGE LIMITE
            comply with the requirements established by law or by VPLUS GREATER
            BAY EXCHANGE LIMITE, or as to the authenticity or reliability of the
            documents submitted. If VPLUS GREATER BAY EXCHANGE LIMITE has
            reasonable doubts that a Payment Order has been submitted by a
            non-legal representative of the Customer, doubts about the
            authenticity of the documents submitted to VPLUS GREATER BAY EXCHANGE
            LIMITE or other doubts about the legality or content of the submitted
            Payment Order, VPLUS GREATER BAY EXCHANGE LIMITE shall have the right
            to request that the Customer, at its own expense, in a manner
            acceptable to VPLUS GREATER BAY EXCHANGE LIMITE, additionally confirm
            the Payment Order submitted and/or provide VPLUS GREATER BAY EXCHANGE
            LIMITE with documents confirming the right of persons to dispose of
            funds in the Account or other specified documents. In the cases
            mentioned in this clause, VPLUS GREATER BAY EXCHANGE LIMITE shall act
            to protect the legitimate interests of the Customer, VPLUS GREATER BAY
            EXCHANGE LIMITE and/or other persons. Therefore, VPLUS GREATER BAY
            EXCHANGE LIMITE shall not be liable for possible losses due to refusal
            to execute a Payment Order. <br/>
            The Customer is obliged to ensure that his Account contains a
            sufficient amount of money in the required currency to execute the
            Customer's Payment Order. <br/>
            Before executing the Customer's Payment Order, VPLUS GREATER BAY
            EXCHANGE LIMITE has the right to request the Customer to provide
            documents proving the legal origin of the funds related to the Payment
            Order. If the Customer fails to provide such documents, VPLUS GREATER
            BAY EXCHANGE LIMITE is entitled to refuse to execute the Payment
            Order. <br/>
            VPLUS GREATER BAY EXCHANGE LIMITE is entitled to engage third parties
            for partial or full execution of the Payment Order provided by the
            Customer, if the Customer's interests and/or the essence of the
            Payment Order to be executed so require. If, in order to properly
            execute a Payment Order, it is necessary to send it to another
            financial institution and further execute it through this institution,
            but this institution, in accordance with its internal procedures,
            stops the execution of the Customer's Payment Order, VPLUS GREATER BAY
            EXCHANGE LIMITE shall not be liable for such actions of the financial
            institution, but shall endeavour to find out the reasons for stopping
            the Payment Order. VPLUS GREATER BAY EXCHANGE LIMITE shall have the
            right to suspend and/or stop the execution of the Customer's Payment
            Order if required by the applicable legislation or it is necessary for
            other reasons beyond the control of VPLUS GREATER BAY EXCHANGE LIMITE.{' '}
            <br/>
            Upon refusal to execute the Customer's Payment Order, VPLUS GREATER
            BAY EXCHANGE LIMITE shall immediately notify the Customer thereof or
            create conditions for the Customer to familiarise himself with such
            notification, unless such notification is technically impossible or
            prohibited by applicable laws and regulations. <br/>
            VPLUS GREATER BAY EXCHANGE LIMITE shall not accept or execute the
            Customer's Payment Orders for transactions using the Account if the
            funds in the Account are seized or the Customer's right to dispose of
            the funds is restricted in any other lawful manner, as well as when
            the transactions carried out by VPLUS GREATER BAY EXCHANGE LIMITE are
            stopped in accordance with applicable laws. <br/>
            If a payment is returned for reasons beyond the control of VPLUS
            GREATER BAY EXCHANGE LIMITE (inaccurate data of the Payment Order, the
            Beneficiary's account is closed, due to the fault of the intermediary
            chosen by the Payer, etc.), the refunded amount shall be credited to
            the Account. The commission fee paid by the Payer for execution of the
            Payment Order shall not be refunded, and VPLUS GREATER BAY EXCHANGE
            LIMITE expenses related to the refund may be debited from the Account.{' '}
            <br/>
            Payment Transfers initiated by VPLUS GREATER BAY EXCHANGE LIMITE, if
            technical possibilities allow, may be urgent or non-urgent. The method
            of the Payment Transfer, if technical possibilities allow, shall be
            chosen by the Customer. If the Customer does not choose the method of
            the Payment Transfer, it shall be deemed that the Customer has
            initiated the Payment Transfer on the most favourable conditions for
            the Customer, which are automatically selected by the System. <br/>
          </p>

          <h2>Restrictions</h2>
          <p className={styles.noMargin}>
            It should be noted that not all products and services offered by VPLUS
            GREATER BAY EXCHANGE LIMITED are universally accessible and may be
            subject to geographical restrictions and specific terms and
            conditions. The eligibility criteria for availing specific products or
            services are determined solely by VPLUS GREATER BAY EXCHANGE LIMITED
            and vpluspay.hk. <br/>
            Prohibited Activities <br/>
            Using the services of VPLUS GREATER BAY EXCHANGE LIMITED, the Client
            is prohibited from:
          </p>

          <ul className={styles.noMargin}>
            <li>
              fail to comply with the terms and conditions of the Agreement and
              its Annexes, laws and other regulatory legal acts, including but not
              limited to non-compliance with legal acts related to the prevention
              of money laundering and terrorist financing;
            </li>
            <li>
              violate the rights of VPLUS GREATER BAY EXCHANGE LIMITED and third
              parties to their trade marks, copyrights, trade secrets and other
              intellectual property rights;
            </li>
            <li>
              provide VPLUS GREATER BAY EXCHANGE LIMITED with incorrect,
              misleading or knowingly false information, as well as refuse to
              provide information or perform other actions reasonably requested by
              VPLUS GREATER BAY EXCHANGE LIMITED;
            </li>
            <li>
              provide third parties with incorrect, misleading or knowingly false
              information about VPLUS GREATER BAY EXCHANGE LIMITED and
              co-operation with VPLUS GREATER BAY EXCHANGE LIMITED;
            </li>
            <li>
              make or receive transfers of funds, if the Client knew or should
              have known that such funds were obtained illegally;
            </li>
            <li>
              use the services of VPLUS GREATER BAY EXCHANGE LIMITED in such a way
              that VPLUS GREATER BAY EXCHANGE LIMITED or other third parties incur
              losses, liability and other negative legal and financial
              consequences or damage their business reputation;
            </li>
            <li>
              use the services of VPLUS GREATER BAY EXCHANGE LIMITED if the
              Customer, its representative, beneficiary and the Payment Transfer
              being made or received fulfil the criteria specified in the list of
              restrictions on the provision of VPLUS GREATER BAY EXCHANGE LIMITED
              services, which is available on the website);
            </li>
            <li>
              distribute computer viruses and take other actions that may disrupt
              the System, damage or destroy information, as well as cause other
              damage to the System, equipment or information of VPLUS GREATER BAY
              EXCHANGE LIMITED;
            </li>
            <li>
              perform any other deliberate actions that may disrupt the provision
              of VPLUS GREATER BAY EXCHANGE LIMITED services to the Customer or
              third parties or the operation of the System;
            </li>
            <li>
              organise illegal gambling and illegal trading in shares, indices,
              commodities, currencies (e.g. Forex), options and exchange traded
              funds (ETFs); provide trading, investment or other services on
              currency exchanges, Forex markets and other electronic currency
              trading systems; engage in illegal trade in tobacco products,
              alcohol, prescription drugs, steroids, weapons, narcotic substances
              and related paraphernalia, pornographic products, unlicensed lottery
              tickets, illegal software and other items and products prohibited by
              law;
            </li>
            <li>
              accept payments in unregulated and/or uncontrolled virtual currency,
              as well as buy, convert or otherwise dispose of it (the prohibition
              also applies to making and receiving transfers from virtual currency
              exchangers, i.e. where the purpose is to make or accept payments in
              regulated currency, but such payments are linked to virtual currency
              exchangers);
            </li>
            <li>
              without the prior written consent of VPLUS GREATER BAY EXCHANGE
              LIMITED, provide financial services and/or legally organise trading
              in shares, indices, commodities, currencies (e.g. Forex), options,
              exchange traded funds (ETF), as well as provide trading, investment
              or other services on currency exchanges, Forex markets and other
              electronic currency trading systems; if the Customer intends to
              provide financial services using the Account, he/she must have a
              valid licence (permit) to carry out the relevant activity without
              the prior written consent of VPLUS GREATER BAY EXCHANGE LIMITED,
              legally organise gambling and lotteries, as well as carry out other
              activities that require licensing or permits - if the Customer
              intends to provide the services specified in this clause using the
              Account, he/she must have a valid licence (permit) to carry out the
              relevant activities and these activities must be monitored by the
              competent authorities for compliance with the relevant requirements;
            </li>
            <li>
              have more than one Account; register the Account in a fictitious
              name or in another person's name without a power of attorney;
              register the Account using the services of persons or websites
              providing anonymous telephone numbers or e-mail addresses;
            </li>
            <li>
              provide services that are prohibited by law or other regulations and
              are incompatible with public order and morals;
            </li>
            <li>
              connect to the System anonymously (e.g. via public intermediate
              (proxy) servers), except for the use of VPN with individual
              characteristics such as static (permanent) IP address, in order to
              ensure the security of data transmission;
            </li>
            <li>
              disclose Passwords and other personalised data related to the
              security of Payment Instruments to third parties and allow them to
              use the services on behalf of the Client.
            </li>
          </ul>
          <br/>

          <p>
            The Customer undertakes to indemnify VPLUS GREATER BAY EXCHANGE
            LIMITED for all direct damages, fines and other monetary sanctions
            imposed on VPLUS GREATER BAY EXCHANGE LIMITED for non-compliance with
            or breach of the Agreement due to the Customer's fault. <br/>
            The Customer shall be liable for any losses of VPLUS GREATER BAY
            EXCHANGE LIMITED, other Customers of VPLUS GREATER BAY EXCHANGE
            LIMITED and third parties related to the Customer's use of VPLUS
            GREATER BAY EXCHANGE LIMITED's services and breach of this Agreement
            or its Appendices, and undertakes to compensate for them. If it turns
            out that the VPLUS GREATER BAY EXCHANGE LIMITED Account was created on
            the basis of forged or fake documents, the sum of 100 (one hundred)
            euros (or an equivalent amount in another currency if the Services are
            provided in another currency) shall be considered the minimum damage
            that does not need to be proved and that VPLUS GREATER BAY EXCHANGE
            LIMITED is entitled to recover from the Customer's Account. A larger
            amount of damage must be proved in writing. VPLUS GREATER BAY EXCHANGE
            LIMITED shall always contact the law enforcement authorities in case
            of establishing signs of illegal activities.
          </p>

          <h2>Confidentiality and data protection</h2>
          <p>
            The Parties undertake to keep each other's technical and commercial
            information confidential, except for publicly available information
            that has become known to the Parties in the course of the performance
            of the Agreement, and not to disclose such information to third
            parties without the written consent of the other Party or its
            authorised representatives. <br/>
            The Customer agrees to VPLUS GREATER BAY EXCHANGE LIMITED processing
            his/her Personal Data for the purpose of providing VPLUS GREATER BAY
            EXCHANGE LIMITED services to the Customer and fulfilling other
            obligations under this Agreement. The Parties shall ensure the
            security of the Personal Data obtained in the course of performance of
            this Agreement. <br/>
            The Customer undertakes to keep secret and not to disclose any
            Passwords or other personalised data created by him or provided to him
            under this Agreement related to the security of the Payment
            Instruments to third parties, as well as not to allow other persons to
            use the services on behalf of the Customer. If the Customer has not
            adhered to this obligation and/or could have, but did not prevent it,
            and/or has committed such acts intentionally or through great
            negligence, it shall be deemed that the connection to the Account and
            the use of the relevant services of VPLUS GREATER BAY EXCHANGE LIMITED
            is on behalf of the Customer. In this case, the Customer shall be
            fully liable for the losses incurred by the Customer in this regard
            and shall indemnify other persons if they have suffered losses as a
            result of the Customer's said acts or omissions. <br/>
            If the Password for connecting to the Account or other Passwords are
            lost by the Customer or become known to third parties through no fault
            of the Customer or VPLUS GREATER BAY EXCHANGE LIMITED, as well as in
            the event that a real threat to the Customer's Account has arisen or
            may arise, the Customer undertakes to notify VPLUS GREATER BAY
            EXCHANGE LIMITED of this immediately (within one calendar day at the
            latest) by means of methods. VPLUS GREATER BAY EXCHANGE LIMITED will
            not be liable for the consequences of not being informed. <br/>
            Upon receiving a communication from the Customer VPLUS GREATER BAY
            EXCHANGE LIMITED shall immediately restrict access to the Customer's
            Account and cease to provide its services until a new Password is
            provided or created for the Customer. <br/>
            VPLUS GREATER BAY EXCHANGE LIMITED draws the Customer's attention to
            the fact that the e-mail associated with the VPLUS GREATER BAY
            EXCHANGE LIMITED Account, as well as other means (e.g. mobile phone
            number) that are linked to the Account at the Customer's discretion,
            are used as a means of communication with and/or identification of the
            Customer. Therefore, the Customer shall ensure the security of these
            means and the data to connect to them. The Customer is solely
            responsible for the security of his/her email and other means used by
            him/her, as well as the Passwords to connect to them. Passwords are
            confidential information, the disclosure of which, as well as all
            transactions performed after entering the Password used by the
            Customer for the respective Account or other Payment Facility, is the
            Customer's own responsibility. VPLUS GREATER BAY EXCHANGE LIMITED
            recommends that Passwords be memorised and not written down or entered
            in any means that can be seen by others. <br/>
            VPLUS GREATER BAY EXCHANGE LIMITED has the right to disclose all
            collected important information about the Client and its activities to
            law enforcement agencies, other government agencies and financial
            institutions, if required by law and in order to find out whether the
            relevant legal acts and this Agreement are or will be violated. <br/>
            The Client authorises VPLUS GREATER BAY EXCHANGE LIMITED, directly or
            through third parties, to take all necessary measures to confirm the
            identity of the Client and the accuracy of other data provided by the
            Client (including but not limited to making appropriate enquiries to
            third parties). <br/>
            VPLUS GREATER BAY EXCHANGE LIMITED draws attention to the fact that in
            all cases, acting as the Customer's Payment Service Provider, VPLUS
            GREATER BAY EXCHANGE LIMITED does not specifically provide or offer
            any services to the Payee until the Payee becomes a Customer of VPLUS
            GREATER BAY EXCHANGE LIMITED. <br/>
            VPLUS GREATER BAY EXCHANGE LIMITED shall have the right to record
            telephone conversations with the Client. The Parties agree that
            recordings of telephone conversations, as well as messages sent by
            post and other means of telecommunication (including e-mail) may be
            used as evidence in resolving disputes between the Client and VPLUS
            GREATER BAY EXCHANGE LIMITED. The Client hereby confirms that he/she
            understands and agrees that VPLUS GREATER BAY EXCHANGE LIMITED may
            record any telephone conversations with the Client and/or his/her
            representative without separate notice or warning. For its part, the
            Customer shall be entitled to record telephone conversations and keep
            such recordings and other correspondence for its own personal use.{' '}
            <br/>
            With the Customer's consent, the Customer's data may also be passed on
            to institutions providing payment initiation services and services
            related to Account information. VPLUS GREATER BAY EXCHANGE LIMITED
            shall be entitled not to grant such institutions access to the
            Customer's Account for objective and duly justified reasons related to
            their unauthorised or illegal access to the Account, including
            unauthorised or illegal initiation of a Payment Transaction. In such
            cases, VPLUS GREATER BAY EXCHANGE LIMITED shall inform the Customer
            that access to the Account has not been granted and shall state the
            reasons for such decision. Such information shall, where possible, be
            provided before the denial of access or immediately thereafter, except
            where the provision of such information may weaken security measures
            or is prohibited by law. <br/>
            In order to protect the legitimate interests of VPLUS GREATER BAY
            EXCHANGE LIMITED, the Customer's data may be disclosed to the media
            when the Customer has contacted the media without using the means to
            protect its rights and when the information provided to the media
            about the dispute does not correspond to the information that VPLUS
            GREATER BAY EXCHANGE LIMITED has at its disposal and damages the
            business reputation of VPLUS GREATER BAY EXCHANGE LIMITED. <br/>
          </p>

          <h2>Copyright and trademark information</h2>
          <p>
            This website may contain logos and trade marks of third parties, in
            which case it is also prohibited to use these trade marks without the
            consent of the third party. Copyright law protects the content of this
            website. Any unauthorised use of information on this website may be
            punishable by law. <br/>
            Links to other websites <br/>
            This website may contain links to other websites operated by third
            parties. VPLUS GREATER BAY EXCHANGE LIMITED provides links to other
            sites as a service to users. The linked sites are not under the
            control of VPLUSGREATER BAY EXCHANGE LIMITED, and VPLUS GREATER BAY
            EXCHANGE LIMITED is not responsible for the content available on third
            party sites. VPLUS GREATER BAY EXCHANGELIMITED does not endorse or
            approve of the material or content of any other sites. Because we
            cannot always be aware of changes in information on a linked site,
            VPLUS GREATER BAY EXCHANGE LIMITED is not responsible for the content
            or accuracy of any third-party site. VPLUS GREATER BAYEXCHANGE LIMITED
            shall not be liable for any loss or damage of any kind arising from
            the use of links on its site, nor shall it be liable for substandard
            products or services advertised or provided on those linked sites.{' '}
            <br/>
            Tools and Calculators <br/>
            This site may contain tools and calculators. VPLUSGREATER BAY EXCHANGE
            LIMITED provides tools and calculators as a service to users, for
            educational purposes only. VPLUS GREATER BAY EXCHANGE LIMITED does not
            guarantee the accuracy of the calculators or results. VPLUS GREATER
            BAY EXCHANGE LIMITED is not responsible for damages of any kind
            resulting from the use of such tools and calculators and expressly
            disclaims all liability. <br/>
          </p>

          <h2>No warranties</h2>
          <p>
            THE INFORMATION ON THIS SITE IS PROVIDED "AS IS" WITHOUT WARRANTY OF
            ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE AND NON-INFRINGEMENT. <br/>
            IN NO EVENT SHALL VPLUS GREATER BAY EXCHANGE LIMITED OR ITS
            SUBSIDIARIES, AFFILIATES, CONTRACTORS OR THEIR RESPECTIVE EMPLOYEES BE
            LIABLE IN ANY CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHER
            ACTION FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
            EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
            PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) ARISING OUT OF THE USE OR
            N <br/>
          </p>

          <h2>Changes</h2>
          <p>
            VPLUS GREATER BAY EXCHANGE LIMITED reserves the right to change the
            Terms of Use and modify the content of the website without prior
            notice.
          </p>

          <h2>Final provisions</h2>
          <p>
            If any part of this agreement is found by a court of competent
            jurisdiction to be unenforceable, the court will modify the
            unenforceable part only to the extent necessary to enforce such part,
            and the remainder of the agreement will remain in full force and
            effect. <br/>
            Each of the Parties confirms that it has all the necessary permits and
            licences required by the current legislation to perform the actions
            necessary for the performance of this Agreement. <br/>
            Titles of sections and articles of this Agreement are for convenience
            of the Parties only and may not be used for interpretation of the
            provisions of the Agreement. <br/>
            <br/>
            The Parties shall be independently responsible for the fulfilment of
            all financial obligations to the State or other entities. VPLUS
            GREATER BAY EXCHANGE LIMITED shall not be responsible for the
            fulfilment of the Customer's financial obligations or for the
            calculation and transfer of the fees charged to the Customer. <br/>
            VPLUS GREATER BAY EXCHANGE LIMITED is in all cases an independent
            Party to the Contract, which has no control over and assumes no
            responsibility for the goods and services paid for using the VPLUS
            GREATER BAY EXCHANGE LIMITED services. Nor does VPLUS GREATER BAY
            EXCHANGE LIMITED assume responsibility for the fulfilment of the terms
            of the transaction by the buyer, seller or other party who enters into
            the transaction with the Customer. <br/>
            The Customer shall not be entitled to transfer the rights and
            obligations under this Agreement to third parties without the prior
            written consent of VPLUS GREATER BAY EXCHANGE LIMITED. VPLUS GREATER
            BAY EXCHANGE LIMITED reserves the right at any time to transfer rights
            and obligations under this Agreement to third parties without the
            Customer's consent, provided that such transfer of rights and
            obligations is not contrary to law. <br/>
            If any of the provisions of this Agreement is recognised as invalid,
            the remaining provisions of the Agreement shall remain in force.{' '}
            <br/>
            This Agreement is available in the System in several languages. For
            the Client the Agreement is valid in the language in which the Client
            has familiarised himself with it during the registration in the
            System. <br/>
            The links to VPLUS GREATER BAY EXCHANGE LIMITED's websites and the
            Appendices governing the provision of individual services specified in
            the Agreement are an integral part of this Agreement and shall become
            effective for the Customer as soon as the Customer starts using the
            respective service. <br/>
            This Agreement shall be governed by and construed in accordance with
            the laws of the HK. All disputes arising out of this Agreement shall
            be subject to the sole and exclusive jurisdiction and venue of the
            state and federal courts located in Hong Kong. The prevailing party in
            any action arising out of this Agreement shall be entitled to recover
            its costs and attorneys' fees. No waiver by either party of rights
            under this Agreement will constitute a subsequent waiver of any right
            under this Agreement, and all waivers must be in writing. In the event
            any term of this Agreement is found by a court to be unenforceable,
            such term will be limited or waived to the minimum extent necessary
            for this Agreement to remain in full force and effect and enforceable.
            Licensee shall not assign or transfer this Agreement (or any part
            thereof) without the prior written consent of the Company. The Company
            shall be free to assign or otherwise transfer this Agreement (in whole
            or in part). All notices required or permitted under this Agreement
            will be in writing (i) if this Agreement may only be modified by a
            written instrument signed by the parties. <br/>
            If you have any questions, please contact VPLUS GREATER BAY EXCHANGE
            LIMITED at support@vpluspay.hk. <br/>
          </p>
        </div>
      </div>
    </PageContainer>
  );
}
