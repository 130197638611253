import { FC } from 'react';
import classNames from 'classnames';
import styles from './TokenCard.module.scss';

interface ITokenCardProps {
  name: string;
  imgSrc: string;
  isChain?: boolean;
  className?: string;
  iconWidth?: number;
}

export const TokenCard: FC<ITokenCardProps> = ({ name, imgSrc, isChain, className, iconWidth }) => {
  return (
    <div
      className={classNames(
        styles.TokenCard,
        "tw-flex tw-flex-col tw-justify-between tw-rounded-1.5xl tw-p-3.5 xl:tw-p-5 xl:tw-text-base tw-bg-secondary-200",
        className,
    )}>
      <div className="tw-flex tw-gap-1 tw-items-center tw-min-w-0">
        <img src={imgSrc} className="tw-size-5 xl:tw-size-7" alt={`token-${name}-icon`} style={{width: iconWidth}}/>
        <span className="tw-truncate">{name}</span>
      </div>
      <div className="tw-text-sm tw-font-semibold">{isChain ? 'Chain type' : 'Coin'}</div>
  </div>
  )
};
