import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {randomBytes} from "otp-io/crypto";
import React, {FormEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Button from "../../../../components/Button";
import Icon from "../../../../components/Icon";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";
import getMe from "../../../../controllers/users/getMe.ts";
import apiClient from "../../../../helpers/apiClient.ts";
import {getRandomNumber} from "../../../../helpers/misc.ts";
import onError from "../../../../helpers/onError.ts";
import validate from "../../../../helpers/validate.ts";
import styles from "./TwoFa.module.scss";

import * as $otp from "otp-io";
import QRCode from "qrcode";

import mainStore from "../../../../store/main.ts";
import { showDangerToast } from '../../../../helpers/toasts.helper.ts';
import { AxiosResponse } from 'axios';
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../helpers/breakpoints.ts';

/* Setup 2FA */

/* Initial form state */
const initialFormState = {
  twoFaCode: ""
};

/* Setup 2FA component */
function AppTwoFaPage_Setup() {
  const $navigate = useNavigate();
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [step, setStep] = useState(1);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
  const [tfaKey, setTfaKey] = useState($otp.generateKey(randomBytes, 5));
  const breakpoint = useBreakpoint();
  const isDesktop = isBreakpointGreaterThan(breakpoint, Breakpoints.XL);
  
  /* Generate QR Code with 2FA */
  useEffect(() => {
    if (tfaKey) {
      QRCode.toDataURL($otp.getKeyUri({
          secret: tfaKey,
          type: "totp",
          issuer: "VPlus",
          name: mainStore.user!.email
        }))
        .then(setQrCodeUrl)
        .catch(onError);
    }
  }, [tfaKey]);
  
  const generateKey = () => {
    setTfaKey($otp.generateKey(randomBytes, 5));
  };

  const handleSetup2faCode = () => {
    if(isFormPending) return;
    setIsFormPending(true);

    Promise.resolve()
      .then(() => {
        /* Validate client data */
        for(const validation of [
          validate("twoFaCode", formState.twoFaCode, "2FA Code")
        ]) {
          if(typeof validation === "string") {
            throw new Error(validation);
          }
        }
      })
      .then(() => {
        /* Send API request */
        return apiClient.post("/users/setTwoFa", {
          twoFaKey: $otp.exportKey(tfaKey),
          twoFaCode: formState.twoFaCode
        });
      })
      .then(() => {
        /* On Done */
        mainStore.addNotification({
          id: getRandomNumber(0, 9999999).toString(16),
          title: `Success`,
          contents: `Now you have 2FA`
        });

        $navigate(`/app/profile`);

        getMe().catch(onError);
      })
      .catch(onError)
      .finally(() => {
        setIsFormPending(false);
      });
  }
  
  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }
  
  /* DOM */
  return (
    <>
      <div className={classNames(
        styles.ContainerSet2FA,
        "tw-flex tw-flex-col tw-h-full tw-bg-secondary-75 tw-rounded-xl xl:tw-rounded-2xl",
      )}>
        <div className={styles.PageFormSetUp2Fa}>
          <div className={styles.PageHeading}>
            Two-factor authentication
          </div>

          <div className="page-form-segment-container">
            {step === 1 && (
              <>
                <div className={classNames(styles.InstallGoogleAuthMessage, 'tw-text-sm xl:tw-text-2.75xl')}>
                  1.Install Google Authenticator app to your
                  device and scan this QR code
                  with your device
                </div>
                <div className="tw-mt-4 xl:tw-mt-11">
                  {qrCodeUrl ? (
                    <img src={qrCodeUrl} alt="" className={styles.twoFaQrCode}/>
                  ) : (
                    <div className="loader-container">
                      <Loader spinsPerSecond={3}/>
                    </div>
                  )}
                </div>
                <div className="tw-flex tw-items-end tw-justify-between tw-gap-4 tw-mt-3.5 tw-font-semibold">
                  <div className={styles.KeyContainer}>
                    <div>
                      <div className="tw-text-secondary tw-text-xs xl:tw-text-sm">Account</div>
                      <div className="tw-text-sm tw-mt-0.5 xl:tw-text-base">Vplus</div>
                    </div>
                    <div className="tw-ms-1 xl:tw-ms-2.5">
                      <div className="tw-text-secondary tw-text-xs xl:tw-text-sm">Key</div>
                      <div className="tw-text-sm tw-mt-0.5 xl:tw-text-base">{$otp.exportKey(tfaKey)}</div>
                    </div>
                    <div className="tw-flex tw-flex-1 tw-justify-end">
                      <Button
                        className="tw-flex-centering tw-size-8.5 xl:tw-size-8.75"
                        additional={{ isSoftGreen: true }}
                        onClick={() => generateKey()}
                      >
                        <Icon icon="rotate" className="tw-size-4"></Icon>
                      </Button>
                    </div>
                  </div>
                  {isDesktop && <Button
                    additional={{ isZeroed: true }}
                    disabled={isFormPending}
                    type="button"
                    onClick={() => setStep(2)}
                  >
                    <span className="tw-text-secondary tw-text-base">Next step</span>

                    {!isFormPending ? (
                      <img className="tw-size-8.75" src="/icons/arrow-right-background.svg" alt=""/>
                    ) : (
                      <Loader spinsPerSecond={3}/>
                    )}
                  </Button>}
                </div>
              </>
            )}

            {step === 2 && (
              <form className="tw-flex tw-flex-col tw-h-full" onSubmit={onFormSubmit}>
                <div className=" tw-leading-tight tw-font-semibold xl:tw-text-2.75xl">
                  Enter an authentication code from
                  <br/>
                  the app here to enable two-factor
                  <br/>
                  authenticaion:
                </div>

                <div className="tw-mt-5 tw-flex-1 xl:tw-mt-10">
                  <TextInput
                    placeholder="Code"
                    value={formState.twoFaCode}
                    onChange={(value) => setFormState({ ...formState, twoFaCode: value })}
                    isBigger={isDesktop}
                    isReadOnly={isFormPending}
                    floatingPlaceholder={!isDesktop}
                  />
                </div>

                {isDesktop &&
                  <div className="tw-flex tw-justify-end tw-items-end">
                    <Button
                      className="tw-text-secondary"
                      additional={{ isZeroed: true }}
                      disabled={isFormPending}
                      onClick={() => setStep(2)}
                    >
                      <span className="tw-text-secondary">Submit</span>

                      {!isFormPending ? (
                        <span className="btn-icon tw-bg-softGreen">
                        <Icon icon="arrow-right-lined-slim" className="tw-h-4"/>
                      </span>
                      ) : (
                        <Loader spinsPerSecond={3}/>
                      )}
                    </Button>
                  </div>
                }
              </form>
            )}
          </div>
        </div>
      </div>
      {!isDesktop && (
        <Button
          additional={{ isZeroed: true }}
          disabled={isFormPending}
          type="button"
          onClick={step === 1 ? () => setStep(2) : handleSetup2faCode}
          className="tw-mt-5 tw-ml-auto"
        >
          <span className="tw-text-secondary tw-text-base">{step === 1 ? 'Next step' : 'Submit'}</span>

          {!isFormPending ? (
            <img className="tw-size-8.75" src="/icons/arrow-right-background.svg" alt="arrow right container"/>
          ) : (
            <Loader spinsPerSecond={3}/>
          )}
        </Button>
      )}
    </>
  );
}

/* Delete 2FA component */
function AppTwoFaPage_Delete() {

  /* Hooks */
  const $navigate = useNavigate();
  const [clickedDeleteBtn, setClickedDeleteBtn] = useState(false);
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const breakpoint = useBreakpoint();
  const isDesktop = isBreakpointGreaterThan(breakpoint, Breakpoints.XL);

  /* Delete 2FA function */
  function deleteTwoFa(e: FormEvent) {
    e.preventDefault();
    
    if(isFormPending) return;
    
    for(const validation of [
      validate("twoFaCode", formState.twoFaCode, "2FA Code")
    ]) {
      if(typeof validation === "string") {
        showDangerToast(validation);
        return;
      }
    }
    
    setIsFormPending(true);
    
    apiClient.post("/users/deleteTwoFa", { twoFaCode: formState.twoFaCode })
    .then((res: AxiosResponse<{ message: string }>) => {
      /* On Done */
      mainStore.addNotification({
        id: getRandomNumber(0, 9999999).toString(16),
        title: `Success`,
        contents: res.data.message,
      });

      $navigate(`/app/profile`);

      getMe()
      .catch(onError);
    })
    .catch(onError)
    .finally(() => setIsFormPending(false));
  }

  /* DOM */
  return (
    <>
      <div className={classNames(
        styles.ContainerDisable2FA,
        "tw-flex tw-flex-col tw-h-full tw-bg-secondary-75 tw-rounded-xl xl:tw-rounded-2xl",
      )}>
        <div className={styles.PageFormDisable2Fa}>
          <div className={classNames(styles.PageHeading, 'tw-mx-3 xl:tw-ml-0')}>
            Two-factor authentication
          </div>

          <div className="tw-flex tw-flex-col tw-h-full tw-w-full">
            <div className={styles.twoFaBlock}>
              <div className="tw-min-w-0">
                <div className={classNames(styles.VplusId, 'tw-truncate')}>Vplus ID: {mainStore.user?._id}</div>

                <div className="tw-flex tw-items-center tw-gap-2.5 tw-mt-1 xl:tw-gap-4">
                  <h5 className="tw-text-2xl tw-text-white xl:tw-text-3.5xl">Status</h5>
                  <div className={styles.StatusTag}>
                    <Icon icon="check-mark-6" className="tw-size-2.5 xl:tw-size-3.5" stroke={'white'} fill={'white'}/>
                    <span>Active</span>
                  </div>
                </div>
              </div>

              <div className="tw-flex tw-flex-col tw-items-center tw-gap-1">
                <Button
                  type="button"
                  className="tw-flex-centering tw-size-7.5 tw-bg-danger tw-rounded-md xl:tw-size-10 xl:tw-rounded-7px"
                  additional={{ isZeroed: true, isAutoWidth: true }}
                  onClick={() => setClickedDeleteBtn(true)}
                  >
                    <Icon icon="minus-circle" stroke="#fff" className="tw-size-4 xl:tw-size-6" />
                  </Button>
                  <span className="tw-text-white tw-text-xss xl:tw-text-sm">Disable</span>
              </div>
            </div>
            {clickedDeleteBtn &&
                <div className="tw-mt-4.5 tw-mx-3 xl:tw-ml-0 xl:tw-mt-8.5">
                    <div className="tw-text-sm xl:tw-text-2.75xl">
                        Enter an authentication code from
                        <br/>
                        the app here to disable two-factor
                        <br/>
                        authenticaion:
                    </div>
                    <div className="tw-mt-5 xl:tw-mt-10">
                      <TextInput
                        placeholder="Code"
                        value={formState.twoFaCode}
                        onChange={(value) => setFormState({ ...formState, twoFaCode: value })}
                        isBigger={isDesktop}
                        isReadOnly={isFormPending}
                        floatingPlaceholder={!isDesktop}
                      />
                    </div>
                </div>
            }
            {isDesktop && <div className="tw-flex tw-justify-end tw-items-end tw-flex-1">
                <Button
                    additional={{ isZeroed: true }}
                    disabled={isFormPending || !clickedDeleteBtn}
                    type="button"
                    onClick={(e) => deleteTwoFa(e)}
                    className="disabled:tw-opacity-100"
                >
                    <span className="tw-text-secondary">Submit</span>
                  {!isFormPending ? (
                    <div
                      className={classNames(
                        'tw-flex-centering tw-size-8.5 tw-rounded-7px',
                        { 'tw-bg-softGreen': clickedDeleteBtn, 'tw-bg-secondary': !clickedDeleteBtn }
                      )}>
                      <Icon icon="arrow-right-lined-slim" className="tw-h-4"/>
                    </div>
                  ) : (
                    <Loader spinsPerSecond={3}/>
                  )}
                </Button>
            </div>}
          </div>
        </div>
      </div>
      {!isDesktop &&
        <Button
          additional={{ isZeroed: true }}
          disabled={isFormPending || !clickedDeleteBtn}
          type="button"
          onClick={(e) => deleteTwoFa(e)}
          className="tw-mt-5.5 tw-ml-auto tw-mt-disabled:tw-opacity-100"
        >
          <span className="tw-text-secondary">Submit</span>
          {!isFormPending ? (
            <div
              className={classNames(
                'tw-flex-centering tw-size-8.5 tw-rounded-7px',
                { 'tw-bg-softGreen': clickedDeleteBtn, 'tw-bg-secondary': !clickedDeleteBtn }
              )}>
              <Icon icon="arrow-right-lined-slim" className="tw-h-4" />
            </div>
          ) : (
            <Loader spinsPerSecond={3}/>
          )}
      </Button>
      }
    </>
  );
}

function AppTwoFaPage() {
  if (!mainStore.user) return null;

  /* DOM */
  if (mainStore.user.twoFaKey) return <AppTwoFaPage_Delete/>;
  else return <AppTwoFaPage_Setup/>;
}

export default observer(AppTwoFaPage);
