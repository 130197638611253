import { FC } from 'react';
import classNames from 'classnames';
import styles from './ActivateCardOverlap.module.scss';
import Button from '../../../../../components/Button';

interface IParams {
  onClickSetPin?: () => void;
}

export const ActivateCardOverlap: FC<IParams> = ({ onClickSetPin }) => {
  return (
    <div className={classNames(styles.Overlap, "tw-w-full tw-h-full tw-flex-centering")}>
      <div className={classNames(styles.OverlapContent, 'tw-flex-centering tw-flex-col tw-text-white tw-text-center')}>
        <h6 className="xl:tw-text-2.5xl">Card activation</h6>
        <p className="tw-text-sm tw-mt-1.5 xl:tw-mt-2.5 xl:tw-text-base">
          To activate your card, please set up a PIN.
          You can complete the activation at any time that is convenient for you.
        </p>
        <Button
          className={classNames(styles.BtnSetPin, 'tw-text-sm xl:tw-text-2sm tw-mt-2.5 xl:tw-mt-6')}
          additional={{ isSoftGreen: true }}
          onClick={onClickSetPin}
        >
          Set PIN
        </Button>
      </div>
    </div>
  );
};