import React, { FormEvent, useCallback, useImperativeHandle, useRef, forwardRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import Select, { SelectOptionModel } from './../../../../../../../../components/Select2';
import TextInput from './../../../../../../../../components/TextInput';
import styles from './KYCStepThree.module.scss';
import { showDangerToast } from './../../../../../../../../helpers/toasts.helper.ts';
import { ActionBtn } from '../../../../../../../../components/ActionBtn/ActionBtn.tsx';
import { cardKYCCountriesStore } from '../../../../../../../../store/card-kyc-countries.store.ts';
import sharedStyles from '../../../kyc-shared-styles.module.scss';

// @ts-ignore
const KYCStepThree = observer(forwardRef<any, any>(({ onNextStepClick, formState, isLoading }, ref) => {
  const nextButtonRef = useRef<HTMLButtonElement>();
  const [data, setFieldValue] = formState;
  const [countryOptions, setCountryOptions] = useState<SelectOptionModel<string>[]>([]);
  
  useEffect(() => {
    setCountryOptions(cardKYCCountriesStore.countries.map(({ id, name: label }) => ({ value: id, label })));
  }, [cardKYCCountriesStore.countries]);
  
  useEffect(() => {
    cardKYCCountriesStore.getCountries();
  }, []);
  
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
      
    if (!data.address.country) {
      showDangerToast('Please select a address country');
      return;
    }
      
    onNextStepClick();
  };

  const setNestedFieldValue = (key: string, value: any) => {
    const keys = key.split('.');
    let currentLevel = formState[0];

    for (let i = 0; i < keys.length - 1; i++) {
      if (!currentLevel[keys[i]]) currentLevel[keys[i]] = {};
      currentLevel = currentLevel[keys[i]];
    }

    currentLevel[keys[keys.length - 1]] = value;
    setFieldValue({ ...formState[0] });
  };
  
  const handleClickNextStep = useCallback(() => {
    if (nextButtonRef.current) {
      nextButtonRef.current.click();
    }
  }, []);
  
  useImperativeHandle(ref, () => ({
    nextStepClick() {
      handleClickNextStep();
    },
  }));
  
  return (
    <form onSubmit={handleSubmit}>
      <div className={classNames(sharedStyles.kycContainer, styles.kycContainer)}>
        <div className={sharedStyles.kycFormSection}>
          <div className={styles.DeliveryInformationTitle}>
            <h2>Delivery Address</h2>
          </div>
          <div className="tw-mt-4 xl:tw-mt-0">
            <p className={sharedStyles.description}>
              To ensure swift delivery of your card, please provide a detailed
              address including the apartment number, if applicable. Incomplete
              addresses may lead to delivery delays.
            </p>
            <div className={classNames(sharedStyles.kycGrid, 'tw-flex tw-flex-col tw-mt-7.5 xl:tw-mt-9')}>
              <Select
                label="Country"
                options={countryOptions}
                selected={countryOptions.find(
                  (opt) => opt.value === data.address.country
                )}
                onSelect={(option) =>
                  setNestedFieldValue('address.country', option.value)
                }
                required
              />
              <TextInput
                type="text"
                name="address.line1"
                label="Delivery Address"
                value={data.address.line1}
                onChange={setFieldValue}
                minLength={3}
                required
              />
            </div>
          </div>
        </div>
        <div className={classNames(sharedStyles.ActionBtn, 'tw-hidden xl:tw-block')}>
          <ActionBtn
            ref={nextButtonRef}
            className="tw-text-secondary tw-ml-auto"
            text="Next Step"
            suffixIcon="custom-arrow-1"
            disabled={isLoading}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div className={classNames(sharedStyles.ActionBtn, 'tw-block xl:tw-hidden')}>
        <ActionBtn
          ref={nextButtonRef}
          className="tw-ml-auto"
          text="Next Step"
          suffixIcon="custom-arrow-1"
          disabled={isLoading}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
}));

export default KYCStepThree;
