import { Box, styled } from '@mui/material';

export const SwiperWrapperCards = styled(Box)(() => ({
  maxWidth: '600px',
  marginLeft: 'auto',
  marginRight: 'auto',
  '.swiper': {
    overflow: 'visible',
    '@media screen and (min-width: 600px)': {
      overflow: 'hidden',
    },
  },
  '.swiper-slide': {
    width: 'calc(250px - 12px)',
    height: '148px',
    '@media screen and (min-width: 375px)': {
      width: 'calc(350px - 12px)',
      height: '207px',
    },
    ':not(.swiper-slide-active)': {
      opacity: 0.6,
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
      },
    }
  },
}));