import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import styles from './PaymentForVirtualCard.module.scss';
import { Link, useParams } from 'react-router-dom';
import { createCardDetailStore } from '../MyCards/store';
import QRCode from 'qrcode';
import { createDepositStore } from '../../../../store/card-deposit.store.ts';
import Loader from '../../../../components/Loader';
import classNames from 'classnames';
import { CardBg } from '../components/CardBg/CardBg.tsx';
import CopyValue from '../../../../components/CopyValue';
import Icon from '../../../../components/Icon';
import { TokenCard } from '../components/TokenCard/TokenCard.tsx';
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../helpers/breakpoints.ts';
import { PageContainer } from '../../../../components/PageContainer/PageContainer.tsx';
import onError from '../../../../helpers/onError.ts';

export const PaymentForVirtualCard = observer(() => {
  const { cardId } = useParams() as { cardId: string };
  const depositStore = useLocalObservable(() => createDepositStore());
  const cardStore = useLocalObservable(() => createCardDetailStore());
  const breakpoint = useBreakpoint();
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);

  const isDesktop = isBreakpointGreaterThan(breakpoint, Breakpoints.XL);

  useEffect(() => {
    if (depositStore.data?.usdtTrcAddress) {
      QRCode.toDataURL(depositStore.data.usdtTrcAddress, { margin: 2 }).then(setQrCodeUrl).catch(onError);
    }
  }, [depositStore.data?.usdtTrcAddress]);

  useEffect(() => {
    depositStore.loadWalletAddress(cardId);
    cardStore.loadCardData(cardId, { loadCardData: true });
  }, []);

  return (
    <PageContainer>
      <div className={classNames(styles.PaymentForPage, 'regular-page tw-text-sm xl:tw-text-base')}>
        <section
          className={styles.CongratulationSection}
        >
          <h2 className="page-title">Congratulations!</h2>
          {cardStore.cardData && <div className="tw-mt-4 xl:tw-mt-6">
              <p>Your virtual card is almost ready.</p>
              <p className="tw-mt-2.5 xl:tw-mt-4">
                  To proceed with your card issuance, please make a payment of
              </p>
              <div
                  className={
                    classNames(
                      styles.PaymentBadge,
                      'tw-flex-centering tw-w-max tw-bg-secondary-200 tw-mt-1.5 xl:tw-mt-3'
                    )}>
                  <img className="tw-size-5 xl:tw-size-7" src="/images/usdt-icon.svg" alt="usdt-icon"/>
                  15 USDT
              </div>
              <p className="tw-mt-2.5 xl:tw-mt-4">
                  A decision regarding your card will be made within the next 12 hours.
              </p>
              <div className={classNames(styles.CardWrapper, 'xl:tw-flex-centering xl:tw-mt-7')}>
                  <CardBg cardType={cardStore.cardData.cardType}/>
              </div>
          </div>
          }
          {cardStore.loading && <div className="tw-position-centering"><Loader/></div>}
        </section>
        <section
          className={styles.DetailsSection}
        >
          <h2 className="page-title">
            Below are the <br/> payment details:
          </h2>
          <div className="tw-flex tw-gap-3.5 tw-mt-3.5 xl:tw-gap-4 xl:tw-mt-6">
            <TokenCard name="USDT" imgSrc="/images/usdt-icon.svg"/>
            <TokenCard name="TRON (TRC20)" imgSrc="/images/tron-icon.svg" isChain/>
          </div>
          <div className="tw-text-sm tw-mt-4 xl:tw-mt-9">
            <div>
              To deposit USDT please go to your wallet and send to the Interlace USDT wallet address below.
              To avoid typing address, copy and paste.
            </div>

            <div className="tw-flex tw-items-center tw-gap-2.5 tw-mt-4 xl:tw-gap-4 xl:tw-mt-5">
              {
                depositStore.isLoading
                  ? <div className="tw-flex-centering tw-w-full"><Loader/></div>
                  : <>
                    <div className={classNames(
                      styles.WalletQrCodeWrapper,
                      'tw-flex-centering tw-border tw-border-black tw-rounded-2lg xl:tw-rounded-2xl tw-overflow-hidden'
                    )}>
                      {qrCodeUrl && <img src={qrCodeUrl} alt="wallet qrcode"/> }
                    </div>
                    <div className="tw-flex-1 tw-min-w-0">
                      <div className="tw-text-xs xl:tw-text-sm tw-text-secondary">Wallet address</div>
                      <div
                        className="tw-flex tw-items-center tw-gap-1.5 tw-text-sm xl:tw-text-base tw-mt-1 xl:tw-mt-2.5 xl:tw-gap-2.5"
                      >
                        <span className="tw-break-all">{depositStore.data?.usdtTrcAddress}</span>
                        <CopyValue value={depositStore.data?.usdtTrcAddress ?? ''} className={styles.CopyWalletIcon}/>
                      </div>
                    </div>
                  </>
              }
            </div>
            <div className="tw-flex-1 tw-mt-5 xl:tw-mt-6.5">
              <p>Be aware!</p>
              <div className="tw-mt-5">
                <p>1. Verify the deposit chain matches the chain you are sending form. Funds sent
                  to an incorrect address may be irrecoverably lost.
                </p>
                <p>2. Only send USDT to this address. Depositing any other token may result in permanent
                  of funds.
                </p>
              </div>
            </div>
            {isDesktop &&
                <div className="tw-flex tw-justify-end tw-mt-16">
                    <Link
                        className="tw-flex tw-items-center tw-gap-3.5 tw-text-secondary tw-text-base"
                        to="/app/cards/get-my-card"
                    >
                        Submit
                        <div className="tw-flex-centering tw-size-8.5 tw-bg-softGreen tw-rounded-7px">
                            <Icon icon="arrow-right-lined-slim" className="tw-h-4"/>
                        </div>
                    </Link>
                </div>
            }
          </div>
        </section>
        {!isDesktop &&
            <div className="tw-flex tw-justify-end tw-mt-2">
                <Link
                    className="tw-flex tw-items-center tw-gap-3.5 tw-text-base"
                    to="/app/cards/get-my-card"
                >
                    Submit
                    <div className="tw-flex-centering tw-size-8.5 tw-bg-softGreen tw-rounded-7px">
                        <Icon icon="arrow-right-lined-slim" className="tw-h-4"/>
                    </div>
                </Link>
            </div>
        }
      </div>
    </PageContainer>
  );
});
