import { SxProps } from '@mui/material';

export const cardDesktopSxProps: SxProps = {
  width: '100%',
  height: '100%',
  borderRadius: '13px',
  '.Card': {
    padding: '41px 35px 32px',
  },
  '.CardHeaderPaymentLogo': {
    height: '30px',
    width: 'auto',
  },
  '.CardHeaderTitle': {
    fontSize: '27px',
    minHeight: '30px',
  },
  '.CardFooterItemLabel': {
    fontSize: '16px',
    minHeight: '20px',
    letterSpacing: '0.491px',
  },
  '.CardFooterItemData': {
    fontSize: '20px',
    minHeight: '22px',
    letterSpacing: '0.364px',
  },
  '.CardCvvDateContainer .CardFooterItemData': {
    letterSpacing: '0.135px',
  },
  '.CardFooter, .CardHeader': {
    gap: '15px',
  },
  '.CardHeaderPaymentInfo': {
    gap: '2px',
  },
  '.CardHeaderCardTier': {
    fontSize: '8px',
    minHeight: '10px',
    letterSpacing: '0.065px',
  },
  '.Card_Visa .CardHeaderPaymentLogo': {
    width: 'auto',
    height: '20px',
  },
}