import React, { FormEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import TextInput from './../../../../../../../../components/TextInput';
import styles from './KYCStepTwo.module.scss';
import { showDangerToast } from './../../../../../../../../helpers/toasts.helper.ts';
import { ActionBtn }  from '../../../../../../../../components/ActionBtn/ActionBtn.tsx';
import Select2, { SelectOptionModel } from '../../../../../../../../components/Select2';
import { cardKYCCountriesStore } from '../../../../../../../../store/card-kyc-countries.store.ts';
import sharedStyles from '../../../kyc-shared-styles.module.scss';

// @ts-ignore
const KYCStepTwo = observer(forwardRef<any, any>(({ onNextStepClick, formState, isLoading }, ref) => {
  const nextButtonRef = useRef<HTMLButtonElement>();
  const [data, setFieldValue] = formState;
  const [countryOptions, setCountryOptions] = useState<SelectOptionModel<string>[]>([]);
  
  useEffect(() => {
    setCountryOptions(cardKYCCountriesStore.countries.map(({ id, name: label }) => ({ value: id, label })));
  }, [cardKYCCountriesStore.countries]);
  
  useEffect(() => {
    cardKYCCountriesStore.getCountries();
  }, []);

  const handleSubmit =
    (e: FormEvent) => {
      e.preventDefault();
      
      if (!data.address.country) {
        showDangerToast('Please select a address country');
        return;
      }
      
      onNextStepClick();
    };
  
  const handleClickNextStep = useCallback(() => {
    if (nextButtonRef.current) {
      nextButtonRef.current.click();
    }
  }, []);
  
  useImperativeHandle(ref, () => ({
    nextStepClick() {
      handleClickNextStep();
    },
  }));

  const setNestedFieldValue = (key: string, value: any) => {
    const keys = key.split('.');
    let currentLevel = formState[0];

    for (let i = 0; i < keys.length - 1; i++) {
      if (!currentLevel[keys[i]]) currentLevel[keys[i]] = {};
      currentLevel = currentLevel[keys[i]];
    }

    currentLevel[keys[keys.length - 1]] = value;
    setFieldValue({ ...formState[0] });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classNames(sharedStyles.kycContainer, styles.kycContainer)}>
        <div className={sharedStyles.kycFormSection}>
          <div>
            <h2 className={styles.ResidentialAddressTitle}>Residential address</h2>
          </div>
          <div>
            <p className={classNames(sharedStyles.description, 'tw-mt-4 xl:tw-mt-0')}>
              To ensure swift delivery of your card, please provide a detailed
              address including the apartment number, if applicable. Incomplete
              addresses may lead to delivery delays.
            </p>
            <div className={classNames(sharedStyles.kycGrid, 'tw-mt-4 xl:tw-mt-9')}>
              <Select2
                options={countryOptions}
                selected={countryOptions.find(
                  (opt) => opt.value === data.address.country
                )}
                onSelect={(option) =>
                  setNestedFieldValue('address.country', option.value)
                }
                label="Country"
                required
              />
              <TextInput
                type="text"
                name="address.city"
                label="City / Town"
                value={data.address.city}
                onChange={setFieldValue}
                required
              />
              <TextInput
                type="text"
                name="address.line1"
                value={data.address.line1}
                label="Area"
                onChange={setFieldValue}
                required
              />
              <TextInput
                type="text"
                name="address.line2"
                label="Street name"
                value={data.address.line2}
                onChange={setFieldValue}
                required
              />
              <TextInput
                type="text"
                label="Building name / House number"
                name="address.line3"
                value={data.address.line3}
                onChange={setFieldValue}
                required
              />
              <TextInput
                type="text"
                name="address.postalCode"
                value={data.address.postalCode}
                label="Postal / ZIP code"
                onChange={setFieldValue}
                required
              />
            </div>
          </div>
        </div>
        <ActionBtn
          ref={nextButtonRef}
          className={classNames(sharedStyles.ActionBtn, 'tw-text-secondary tw-ml-auto tw-hidden xl:tw-flex')}
          text="Next Step"
          suffixIcon="custom-arrow-1"
          disabled={isLoading}
          isLoading={isLoading}
        />
      </div>
      <ActionBtn
        ref={nextButtonRef}
        className={classNames(sharedStyles.ActionBtn, 'tw-ml-auto tw-flex xl:tw-hidden')}
        text="Next Step"
        suffixIcon="custom-arrow-1"
        disabled={isLoading}
        isLoading={isLoading}
      />
    </form>
  );
}));

export default KYCStepTwo;
