import classNames from "classnames";
import {ComponentProps} from "react";
import styles from "./index.module.scss";

export default function Button(
  props: ComponentProps<"button"> & {
    additional?: {
      isTransparent?: boolean,
      isPositive?: boolean,
      isNegative?: boolean,
      isZeroed?: boolean,
      isAutoWidth?: boolean,
      isSoftGreen?: boolean,
      isGray?: boolean,
    }
  }
) {

  /* DOM */
  return (
    <button
      {...props}
      className={classNames(
        styles.Button,
        props.className,
        {isTransparent: props.additional?.isTransparent},
        {isPositive: props.additional?.isPositive},
        {isNegative: props.additional?.isNegative},
        {isZeroed: props.additional?.isZeroed},
        {isAutoWidth: props.additional?.isAutoWidth},
        {isSoftGreen: props.additional?.isSoftGreen},
        {isGray: props.additional?.isGray},
      )}
    />
  );
}
