import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { observer, useLocalObservable } from 'mobx-react-lite';
import Button from './../../../../../../components/Button';
import { CardStatusOverlay } from './../../components/CardStatusOverlay/CardStatusOverlay.tsx';
import { createCardsStore } from '../../../../Cards/MyCards/store';
import {
  CardStatus,
  CardType,
  CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeMastercardVirtual,
  CardTypeUnionpay,
  CardTypeVisaPhysical,
  ICardDto
} from '../../../../../../services/card-api';
import styles from './SelectCardType.module.scss';
import { cardKindString, isPhysicalCard, isVirtualCard } from '../../../../../../helpers/card-kind.helper.ts';
import Loader from '../../../../../../components/Loader';
import Icon from '../../../../../../components/Icon';
import { SliderCard } from './SliderCard.ts';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useScreenWidth } from '../../../../../../hooks/useScreenWidth.ts';
import { SwiperWrapperCards } from './SwiperWrapperCards.ts';
import GetMyCardBlock from '../GetMyCardBlock/GetMyCardBlock.tsx';

export interface CardProps {
  status: string;
  kind: string;
  cardType: CardType;
}

const sliderCardTypes = [
  CardTypeMastercardPhysical.PhysicalMastercard,
  CardTypeVisaPhysical.PhysicalVisa,
  CardTypeApplePay.VirtualApplePay,
  CardTypeMastercardVirtual.VirtualMastercardSuperTransfer,
];

type CardsByTypeMap = Partial<Record<CardType, ICardDto>>;

const SelectCardType: React.FC<{
  initialCardType: string | null;
  onCardTypeSelect: (cardType: CardType) => void;
  isLoading: boolean;
  handleClickGetMyCard: () => void,
}> = observer(({ initialCardType, onCardTypeSelect, isLoading, handleClickGetMyCard }) => {
  const navigate = useNavigate();
  const [selectedCardType, setSelectedCardType] = useState<CardType>(null);
  const [cardStatuses] = useState<{ [key: string]: string }>({});
  const cardsStore = useLocalObservable(createCardsStore);
  const screenWidth = useScreenWidth();
  const [activeSliderIndex, setActiveSliderIndex] = useState<CardType>(0);

  const activeSliderCardType = sliderCardTypes[activeSliderIndex];
  const existedCardByTypeMap: CardsByTypeMap = getExistedCardStatuses(cardsStore.cards);
  const activeSliderCard: ICardDto | null = existedCardByTypeMap[activeSliderCardType] ?? null;

  function getExistedCardStatuses(cards: ICardDto[]) {
    return (cards ?? []).reduce((res, card) => {
      res[card.cardType] = { ...card };
      return res;
    }, {} as CardsByTypeMap);
  }

  useEffect(() => {
    cardsStore.loadCards();
  }, []);

  const cardNetworkByCardTypeMap: Partial<Record<CardType, string>> = {
    [CardTypeMastercardPhysical.PhysicalMastercard]: 'Mastercard Platinum',
    [CardTypeVisaPhysical.PhysicalVisa]: 'VISA Platinum',
    [CardTypeUnionpay.VirtualUnionpay]: 'UnionPay',
    [CardTypeApplePay.VirtualApplePay]: 'Mastercard',
    [CardTypeMastercardVirtual.VirtualMastercardSuperTransfer]: 'Mastercard',
  };

  const isSmallSliderCards = screenWidth <= 375;
  
  const handleCardClick = (cardType: CardType) => {
    if (existedCardByTypeMap[cardType] || cardsStore.loading || isLoading)
      return;
    const status = cardStatuses[cardType] || 'available';
    if (status !== 'pending') {
      setSelectedCardType(cardType);
      onCardTypeSelect(cardType);
    }
  };

  const createNextBtnLink = (card?: ICardDto) => {
    if (!card) return null;
    const { cardType, id, status } = card;
    if (isVirtualCard(cardType) && status === CardStatus.WAITING_FOR_PAYMENT) {
      return `/app/cards/${id}/payment-for-virtual-card`;
    }
    const physicalCardMatchStatuses = status === CardStatus.WAITING_FOR_PAYMENT
      || status === CardStatus.KYCPending || status === CardStatus.KYCFailed;
    if (isPhysicalCard(cardType) && physicalCardMatchStatuses) {
      return `/app/cards/${id}/payment-for-physical-card`;
    }
    return null;
  };

  const createHeaderBtnLink = (card?: ICardDto) => {
    if (!card) return null;
    const { id, status } = card;
    if (status === CardStatus.ACTIVE)  return `/app/cards?cardId=${id}`;
    return null;
  };
  
  return (
    <section>
      <h2 className="tw-text-1.5xl">Select the type of debit card</h2>
      <div className="tw-hidden xl:tw-block tw-mt-5">
        <div className={classNames(styles.selectCardType)}>
          {selectedCardType ? (
            <>
              <div className={styles.selectedCardType}>
                <div className={styles.leftColumn}>
                  <div className={styles.unselectedCards}>
                    <div className={styles.miniCards}>
                      {[
                        CardTypeMastercardPhysical.PhysicalMastercard,
                        CardTypeVisaPhysical.PhysicalVisa,
                        CardTypeApplePay.VirtualApplePay,
                        CardTypeMastercardVirtual.VirtualMastercardSuperTransfer,
                      ]
                        .filter((cardType) => !existedCardByTypeMap[cardType])
                        .map((cardType) => (
                          <button
                            key={cardType}
                            className={classNames(
                              styles.miniCard,
                              cardType === CardTypeMastercardPhysical.PhysicalMastercard && styles.msCard,
                              cardType === CardTypeVisaPhysical.PhysicalVisa && styles.visaCard,
                              cardType === CardTypeApplePay.VirtualApplePay && styles.visaApplePayCard,
                              cardType === CardTypeMastercardVirtual.VirtualMastercardSuperTransfer && styles.mastercardVirtual,
                              selectedCardType === cardType && styles.selectedMiniCard,
                            )}
                            disabled={isLoading}
                            onClick={() => handleCardClick(cardType)}
                          />
                        ))}
                    </div>
                  </div>
                  <div className={styles.selectedCard}>
                    <div
                      className={classNames(
                        styles.selectedCardImage,
                        selectedCardType === CardTypeMastercardPhysical.PhysicalMastercard && styles.msCard,
                        selectedCardType === CardTypeVisaPhysical.PhysicalVisa && styles.visaCard,
                        selectedCardType === CardTypeApplePay.VirtualApplePay && styles.visaApplePayCard,
                        selectedCardType === CardTypeMastercardVirtual.VirtualMastercardSuperTransfer && styles.mastercardVirtual,
                        isLoading && styles.selectedCardImageLoading,
                        "tw-relative tw-overflow-visible",
                      )}
                    >
                      {isLoading &&
                          <div className="tw-position-centering">
                              <Loader/>
                          </div>
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.rightColumn}>
                  <h2 className={styles.rightColumnTitle}>
                    {cardKindString(selectedCardType)} card
                  </h2>
                  <div className="tw-flex tw-gap-4">
                    <div className={classNames(styles.infoBlock,)}>
                      <div className={classNames(styles.infoBlockBg, styles.cardTypeBg)}></div>
                      <div className={styles.infoBlockText}>
                        Card network: <br/>
                        {cardNetworkByCardTypeMap[selectedCardType] ?? ''}
                      </div>
                    </div>
                    <div className={classNames(styles.infoBlock)}>
                      <div className={classNames(styles.infoBlockBg, styles.fundingMethodBg)}></div>
                      <div className={styles.infoBlockText}>
                        Funding method: <br/>
                        USD Tether (USDT)
                      </div>
                    </div>
                    <div className={classNames(styles.infoBlock)}>
                      <div className={classNames(styles.infoBlockBg, styles.paymentBg)}></div>
                      <div className={styles.infoBlockText}>
                        {isPhysicalCard(selectedCardType)
                          ? 'Payment for goods and services, cash withdrawals'
                          : 'Payment for goods and services'
                        }
                      </div>
                    </div>
                  </div>

                  <div>
                    <Button
                      className="tw-text-sm"
                      style={{ width: '120px', height: '30px' }}
                      onClick={() => navigate('/app/cards/compare')}
                      additional={{ isSoftGreen: true }}
                    >
                      Learn more
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.cardColumn}>
                <h3 className={styles.cardTitle}>Physical cards</h3>
                <div className={styles.cardsList}>
                  <div
                    className={classNames(styles.msCard, styles.card, {
                      [styles.CardSelectBlocked]:
                      cardsStore.loading || existedCardByTypeMap[CardTypeMastercardPhysical.PhysicalMastercard]
                    })}
                    onClick={() => handleCardClick(CardTypeMastercardPhysical.PhysicalMastercard)}>
                    {existedCardByTypeMap[CardTypeMastercardPhysical.PhysicalMastercard]?.status && (
                      <CardStatusOverlay
                        size={'sm'}
                        status={existedCardByTypeMap[CardTypeMastercardPhysical.PhysicalMastercard]!.status}
                        headerLink={createHeaderBtnLink(existedCardByTypeMap[CardTypeMastercardPhysical.PhysicalMastercard])}
                        nextBtnLink={createNextBtnLink(existedCardByTypeMap[CardTypeMastercardPhysical.PhysicalMastercard])}
                      />
                    )}
                  </div>
                  <div
                    className={classNames(styles.visaCard, styles.card, {
                      [styles.CardSelectBlocked]:
                      cardsStore.loading || existedCardByTypeMap[CardTypeVisaPhysical.PhysicalVisa]
                    })}
                    onClick={() => handleCardClick(CardTypeVisaPhysical.PhysicalVisa)}>
                    {existedCardByTypeMap[CardTypeVisaPhysical.PhysicalVisa] && (
                      <CardStatusOverlay
                        size={'sm'}
                        status={existedCardByTypeMap[CardTypeVisaPhysical.PhysicalVisa]!.status}
                        headerLink={createHeaderBtnLink(existedCardByTypeMap[CardTypeVisaPhysical.PhysicalVisa])}
                        nextBtnLink={createNextBtnLink(existedCardByTypeMap[CardTypeVisaPhysical.PhysicalVisa])}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.cardColumn}>
                <h3 className={styles.cardTitle}>Virtual cards</h3>
                <div className={styles.cardsList}>
                  <div
                    className={classNames(styles.visaApplePayCard, styles.card, {
                      [styles.CardSelectBlocked]:
                      cardsStore.loading ||
                      existedCardByTypeMap[CardTypeApplePay.VirtualApplePay]
                    })}
                    onClick={() => handleCardClick(CardTypeApplePay.VirtualApplePay)}>
                    {existedCardByTypeMap[CardTypeApplePay.VirtualApplePay] && (
                      <CardStatusOverlay
                        size={'sm'}
                        status={existedCardByTypeMap[CardTypeApplePay.VirtualApplePay]!.status}
                        headerLink={createHeaderBtnLink(existedCardByTypeMap[CardTypeApplePay.VirtualApplePay])}
                        nextBtnLink={createNextBtnLink(existedCardByTypeMap[CardTypeApplePay.VirtualApplePay])}
                      />
                    )}
                  </div>
                  <div
                    className={classNames(styles.mastercardVirtual, styles.card, {
                      [styles.CardSelectBlocked]:
                      cardsStore.loading ||
                      existedCardByTypeMap[CardTypeMastercardVirtual.VirtualMastercardSuperTransfer]
                    })}
                    onClick={() => handleCardClick(CardTypeMastercardVirtual.VirtualMastercardSuperTransfer)}>
                    {existedCardByTypeMap[CardTypeMastercardVirtual.VirtualMastercardSuperTransfer] && (
                      <CardStatusOverlay
                        size={'sm'}
                        status={existedCardByTypeMap[CardTypeMastercardVirtual.VirtualMastercardSuperTransfer]!.status}
                        headerLink={createHeaderBtnLink(existedCardByTypeMap[CardTypeMastercardVirtual.VirtualMastercardSuperTransfer])}
                        nextBtnLink={createNextBtnLink(existedCardByTypeMap[CardTypeMastercardVirtual.VirtualMastercardSuperTransfer])}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="tw-mt-6.5">
          <GetMyCardBlock onClickGetMyCard={() => handleClickGetMyCard()} isDisabledBtn={isLoading} />
        </div>
      </div>
      <div className="tw-block xl:tw-hidden tw-mt-8">
        <SwiperWrapperCards>
          <Swiper
            centeredSlidesBounds={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            spaceBetween={12}
            onSlideChange={(swiper) => setActiveSliderIndex(swiper.activeIndex)}
            breakpoints={{
              600: { slidesPerView: 1 },
            }}
          >
            {sliderCardTypes.map((cardType) => (
              <SwiperSlide key={cardType}>
                <SliderCard
                  className={
                    classNames(
                      styles.CardSlider,
                      {
                        [styles.visaApplePayCard]: cardType === CardTypeApplePay.VirtualApplePay,
                        [styles.visaCard]: cardType === CardTypeVisaPhysical.PhysicalVisa,
                        [styles.msCard]: cardType === CardTypeMastercardPhysical.PhysicalMastercard,
                        [styles.mastercardVirtual]: cardType === CardTypeMastercardVirtual.VirtualMastercardSuperTransfer,
                      },
                    )
                  }
                >
                  {existedCardByTypeMap[cardType] && (
                    <CardStatusOverlay
                      size={isSmallSliderCards ? 'sm' : 'md'}
                      status={existedCardByTypeMap[cardType]!.status}
                      headerLink={createHeaderBtnLink(existedCardByTypeMap[cardType])}
                      nextBtnLink={createNextBtnLink(existedCardByTypeMap[cardType])}
                    />
                  )}
                </SliderCard>
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperWrapperCards>
        <div className="tw-text-center tw-mt-8">
          <h4 className="tw-text-secondary">You have selected</h4>
          <div className="tw-text-1.5xl tw-mt-0.5">
            <span>{cardKindString(activeSliderCardType)} card</span>
          </div>
        </div>
        {
          cardsStore.loading
            ? <div className="tw-mt-12 tw-flex-centering"><Loader/></div>
            : <>
              {!activeSliderCard && !cardsStore.error &&
                  <>
                      <div className={classNames(styles.CardInfoBlockMobile, 'tw-mt-6')}>
                          <div className={classNames(styles.infoBlock)}>
                              <div className={classNames(styles.infoBlockBg, styles.cardTypeBg)}></div>
                              <div className={styles.infoBlockText}>
                                  Card network: <br/>
                                {cardNetworkByCardTypeMap[activeSliderCardType] ?? ''}
                              </div>
                          </div>
                          <div className={classNames(styles.infoBlock)}>
                              <div className={classNames(styles.infoBlockBg, styles.fundingMethodBg)}></div>
                              <div className={styles.infoBlockText}>
                                  Funding method: <br/>
                                  USD Tether (USDT)
                              </div>
                          </div>
                          <div className={classNames(styles.infoBlock)}>
                              <div className={classNames(styles.infoBlockBg, styles.paymentBg)}></div>
                              <div className={styles.infoBlockText}>
                                {isPhysicalCard(activeSliderCardType)
                                  ? 'Payment for goods and services, cash withdrawals'
                                  : 'Payment for goods and services'
                                }
                              </div>
                          </div>
                          <Link
                              className={classNames(styles.infoBlock, 'tw-bg-softGreen')}
                              to="/app/cards/compare"
                          >
                              <div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-w-full">
                                  <div className={classNames(styles.infoBlockBg, styles.learnMoreBg)}></div>
                                  <Icon icon="arrow-right" className={'tw-size-2.5'}/>
                              </div>
                              <span className="tw-text-base">Learn more</span>
                          </Link>
                      </div>
                      <GetMyCardBlock
                          className="tw-mt-4"
                          onClickGetMyCard={() => handleClickGetMyCard(activeSliderCardType)}
                          isDisabledBtn={isLoading}
                      />
                  </>
              }
              {
                activeSliderCard && !cardsStore.error &&
                  <div className="tw-p-4.5 tw-bg-secondary-75 tw-rounded-2xl tw-text-sm tw-mt-4.5">
                      <div className={
                        classNames(
                          styles.CardStatusDescription,
                          {
                            [styles['CardStatusDescription_underReview']]: activeSliderCard.status === CardStatus.KYCPending,
                            [styles['CardStatusDescription_rejected']]: activeSliderCard.status === CardStatus.KYCFailed,
                            [styles['CardStatusDescription_active']]: activeSliderCard.status === CardStatus.ACTIVE,
                            [styles['CardStatusDescription_waitingForPayment']]: activeSliderCard.status === CardStatus.WAITING_FOR_PAYMENT,
                          },
                          'tw-mx-auto tw-text-center'
                        )}>
                        {activeSliderCard.status === CardStatus.KYCPending && (
                          <>
                            Our compliance team will review your information, and you can expect a decision on your
                            card within 48 hours. We will notify you via the email address you registered with.
                          </>
                        )}
                        {activeSliderCard.status === CardStatus.KYCFailed && (
                          <>
                            <p>
                              We regret to inform you that, unfortunately, we could not approve your card application.
                            </p>
                            <p className="tw-mt-3.5">
                              For more detailed information, please reach out to our support team at
                              &#32;
                              <NavLink to={'mailto:support@vpluspay.hk'} className="tw-text-blue tw-underline">
                                support@vpluspay.hk
                              </NavLink>. We are here to assist you with any questions or concerns you may have.
                            </p>
                          </>
                        )}
                        {activeSliderCard.status === CardStatus.WAITING_FOR_PAYMENT && (
                          <>
                            If you have already made the payment, please allow a moment while your transaction is being
                            processed. We appreciate your patience!
                          </>
                        )}
                        {activeSliderCard.status === CardStatus.ACTIVE && (
                          <>
                            Your card has been successfully issued! If you're interested in other cards available for
                            issuance,
                            we recommend checking out the information on
                            &#32;
                            <NavLink to="/app/cards/compare" className="tw-text-blue tw-underline">
                              this page
                            </NavLink>.
                          </>
                        )}
                      </div>
                  </div>
              }
            </>
        }
      </div>
    </section>
  );
});

export default SelectCardType;
