import { observer, useLocalObservable } from 'mobx-react-lite';
import { PinControl } from './../../../Cards/components/PinControl/PinControl.tsx';
import { createPinDialogContentStore } from './store/CardPinDialogContent.store.ts';
import styles from './CardPinDialogContent.module.scss';
import cn from 'classnames';
import Button from '../../../../../components/Button';
import { useEffect } from 'react';

const cardPinLength = 6;

interface IParams {
  cardId: string;
  onChangedPin?: () => void;
}

export const CardPinDialogContent = observer<IParams>(({ cardId, onChangedPin }) => {
  const store = useLocalObservable(() => createPinDialogContentStore(cardId));

  useEffect(() => {
    if (store.successChanged && onChangedPin) onChangedPin();
  }, [store.successChanged]);
  
  return (
    <div className={cn(styles.InnerDialogContent, 'tw-flex tw-flex-col tw-items-center')}>
      {
        !store.successChanged &&
          <div className="tw-flex tw-flex-col tw-justify-end tw-items-center tw-flex-1">
            <h5 className="tw-text-center tw-text-lg tw-text-black-700 xl:tw-text-2.5xl">
                Please enter the 6-digit PIN code
            </h5>
            <div className="tw-mt-4.5 xl:tw-mt-7">
              <PinControl length={cardPinLength} onChange={(value) => store.setCardPinValue(value)}></PinControl>
            </div>
            <Button
                className={cn(styles.CardPinSaveBtn, 'tw-mt-5.5 xl:tw-mt-8')}
                additional={{ isSoftGreen: true }}
                disabled={!store.isEnableBtnSave || store.isLoading}
                onClick={() => store.updateCardPin(store.cardPinValue)}
            >
                Save
            </Button>
          </div>
      }
      {
        store.successChanged &&
          <>
              <div className={styles.SuccessBg}></div>
              <h5 className={cn(
                styles.MessageSuccessChangePin,
                "tw-text-center tw-mt-2 tw-text-lg tw-text-black-700 xl:tw-text-2.5xl"
              )}>
                Your PIN code has been successfully set
              </h5>
          </>
      }
    </div>
  );
});