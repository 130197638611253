import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import {
  CardType,
  CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeMastercardVirtual,
  CardTypeUnionpay,
  CardTypeVisaPhysical
} from '../../../../../services/card-api';
import styles from './CardBg.module.scss';
import Loader from '../../../../../components/Loader';
import { Box, SxProps, Theme } from '@mui/material';

interface CardBgProps {
  cardType?: CardType;
  isLoading?: boolean;
  className?: string;
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

export const CardBg: FC<CardBgProps> = ({ cardType, isLoading, className, children, sx }) => {
  const getScrCard = (cardType?: CardType) => {
    switch (cardType) {
      case CardTypeMastercardPhysical.PhysicalMastercard:
        return '/images/ms-physical-card.svg';
      case CardTypeVisaPhysical.PhysicalVisa:
        return '/images/visa-physical-card.svg';
      case CardTypeUnionpay.VirtualUnionpay:
        return '/images/unio-virtual-card.svg';
      case CardTypeApplePay.VirtualApplePay:
        return '/images/mastercard-virtual-apay-card.svg';
      case CardTypeMastercardVirtual.VirtualMastercardSuperTransfer:
        return '/images/mastercard-virtual-card.svg';
    }
  };
  const cardSrc = getScrCard(cardType);
  return (
    <Box
      className={classNames(
        styles.CardBg,
        isLoading && styles.CardBgLoading,
        className,
        "tw-relative tw-overflow-hidden",
      )}
      sx={sx}
    >
      {cardSrc && <img src={cardSrc} alt={cardType}/> }
      {isLoading && (
        <div className="tw-position-centering">
          <Loader/>
        </div>
      )}
      { children }
    </Box>
  );
};
