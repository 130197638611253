import {Link} from 'react-router-dom';
import styles from './index.module.css';
import { PageContainer } from '../../components/PageContainer/PageContainer.tsx';

export default function PrivacyPolicyPage() {

  /* DOM */
  return (
    <PageContainer>
      <div className={styles.PrivacyPolicyPage}>
        <div className={styles.container}>
          <Link to="/">
            <img
              className={styles.logo}
              src="/vplus-dark-full.svg"
              alt="logo"
            />
          </Link>
          <h2 className={styles.pageTitle}>
            VPLUS Greater Bay Exchange Limited Privacy Policy and Cookies Policy
          </h2>
          <p className={styles.pageTitleSubTitle}>
            Date of the latest revision: April 16, 2024 Effective date: April 16,
            2024
          </p>

          <h2>Introduction</h2>
          <p>
            This VPLUS Greater Bay Exchange Limited Privacy Policy (hereinafter
            referred to as "Privacy Policy") applies to all payment products,
            services and their extended functions (hereinafter referred to as
            "VPLUSPAY Services") provided by VPLUSPAY Greater Bay Exchange
            Limited. ("We" or “VPLUSPAY”). If there is any specific privacy policy
            or provision tailored for certain products provided by us, such
            specific privacy policy or provision shall prevail; for the contents
            not covered by the specific privacy policy or provision, this Privacy
            Policy shall apply.
          </p>

          <div className={styles.contactInfo}>
            <h2>Contact information of VPLUSPAY:</h2>
            <p>
              Registered Address: HONG KONG, FLAT 2, 11/F, YUEN LONG TRADE CENTRE,
              99-109 CASTLE PEAK ROAD, YUEN LONG, NEW TERRITORIES.
            </p>
            <p>
              Office Address: HONG KONG, FLAT 2, 11/F, YUEN LONG TRADE CENTRE,
              99-109 CASTLE PEAK ROAD, YUEN LONG, NEW TERRITORIES.
            </p>
            <p>Customer Hotline: support@vpluspay.hk</p>
            <p>
              Official Website:{' '}
              <a href="https://www.vpluspay.hk">www.vpluspay.hk</a>
            </p>

            <p>
              VPLUS Greater Bay Exchange Limited ("we", "us", "our") is committed
              to protecting your privacy. This Privacy Policy outlines our
              practices concerning the collection, use, and protection of your
              personal information. We are a licensed Operating Money Service
              Operator in Hong Kong, regulated by the Customs and Excise
              Department. This policy complies with the Hong Kong Personal Data
              (Privacy) Ordinance (DPDO) and the General Data Protection
              Regulation (GDPR).
            </p>
          </div>

          <h2 className={styles.helpTitle}>
            This Privacy Policy will help you understand the following:
          </h2>
          <ol>
            <li>your rights</li>
            <li>how we collect your personal information;</li>
            <li>how we use cookies and other similar technologies;</li>
            <li>how we store and protect your personal information;</li>
            <li>how we use your personal information;</li>
            <li>how we provide your personal information;</li>
            <li>
              circumstances where personal information could be processed without
              your consent;
            </li>
            <li>how we protect the rights and interests of minors;</li>
            <li>how we update this privacy policy;</li>
            <li>how to contact us.</li>
          </ol>

          <h2>Your Rights</h2>
          <p className={styles.noMargin}>
            Under the DPDO and GDPR, you have several rights including:
          </p>
          <ul>
            <li>The right to access your personal data.</li>
            <li>
              The right to request the correction of inaccurate personal data.
            </li>
            <li>The right to request erasure of your personal data.</li>
            <li>The right to object to processing of your personal data.</li>
            <li>
              The right to request restriction on the processing of your personal
              data.
            </li>
            <li>The right to data portability.</li>
          </ul>

          <h2>How We Collect Your Personal Information</h2>
          <p>
            We collect personal information that you provide directly to us
            through our services, application processes, and customer support
            interactions. Additionally, we may collect information through
            automatic means such as communications protocols, email
            communications, cookies, and other similar technologies.
          </p>

          <h2>How We Use Cookies and Other Similar Technologies</h2>
          <p className={styles.noMargin}>
            We use cookies and similar tracking technologies to track activity on
            our service and hold certain information. Cookies are files with a
            small amount of data which may include an anonymous unique identifier.
          </p>
          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent.
          </p>

          <h2>How We Store and Protect Your Personal Information</h2>
          <p>
            We store your personal information on secure servers and take
            appropriate security measures to prevent unauthorized access,
            disclosure, modification, or unauthorized destruction of the data. We
            adhere to strict security standards mandated by the DPDO and GDPR.
          </p>

          <h2>How We Use Your Personal Information</h2>
          <p>
            We use your personal information to provide and improve our services,
            process transactions, comply with our legal and regulatory
            obligations, and for customer service purposes.
          </p>

          <h2>How We Provide Your Personal Information</h2>
          <p>
            We may share your personal information with our subsidiaries,
            affiliated companies, and trusted third-party service providers as
            necessary for the purposes outlined in this policy. We ensure that
            these parties are bound by confidentiality obligations and appropriate
            data protection laws.
          </p>

          <h2>
            Circumstances Where Personal Information Could Be Processed Without
            Your Consent
          </h2>
          <p>
            In certain circumstances, we may process your information without your
            consent where this is required or permitted by law, such as when
            complying with legal obligations, preventing fraud, or ensuring
            network and information security.
          </p>

          <h2>How We Protect the Rights and Interests of Minors</h2>
          <p>
            We do not knowingly collect or solicit personal information from
            individuals under the age of 18. If we become aware that a minor has
            provided us with personal information, we take steps to remove such
            information and terminate the minor's account.
          </p>

          <h2>How We Update This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify you
            of any changes by posting the new Privacy Policy on this page. We will
            let you know via email and/or a prominent notice on our Service, prior
            to the change becoming effective.
          </p>

          <h2>How to Contact Us</h2>
          <p className={styles.noMargin}>
            If you have any questions about this Privacy Policy, please contact
            us:
          </p>
          <ul>
            <li>By email: support@vpluspay.hk</li>
            <li>
              By mail: HONG KONG, FLAT 2, 11/F, YUEN LONG TRADE CENTRE, 99-109
              CASTLE PEAK ROAD, YUEN LONG, NEW TERRITORIES.
            </li>
          </ul>
        </div>
      </div>
    </PageContainer>
  );
}
