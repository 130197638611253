import styles from './CardTransactionsPage.module.scss';
import { Table } from './../../../../components/Table';
import { TBody } from './../../../../components/Table/TBody';
import { TRow } from './../../../../components/Table/TRow';
import { THead } from './../../../../components/Table/THead';
import { TCell } from './../../../../components/Table/TCell';
import { FC, memo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DatePicker, { DatePickerStateModel } from './../../../../components/DatePicker';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { createCardTransactionsStore } from './../MyCards/store/CardTransactions.store.ts';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { ICardTransactionDto, TransactionStatus } from '../../../../services/card-api/interfaces';
import {
  getOperationTypeText,
  transactionExecutionTextByStatusMap
} from '../../../../helpers/transaction.helper.ts';
import { convertCentsToCurrency } from '../../../../helpers/convert-cents-to-currency.helper.ts';
import formatBalance from '../../../../helpers/formatBalance.ts';
import Loader from '../../../../components/Loader';
import { PageContainer } from '../../../../components/PageContainer/PageContainer.tsx';
import cn from 'classnames';
import { Box, SxProps } from '@mui/material';

type IAmountTmplProps = Pick<ICardTransactionDto, 'currency' | 'localCurrency' | 'localAmount' | 'amount'> & {
  className?: string;
  sx?: SxProps;
};

type ITransactionStatusTmplProps = Pick<ICardTransactionDto, 'status'> & { className?: string; };

const AmountTmpl: FC<IAmountTmplProps> = ({ currency, localCurrency, amount, localAmount, sx }) => {
  return (
    <Box sx={sx}>
      <span className="LocalCurrency">
        {formatBalance(convertCentsToCurrency(localAmount))} {localCurrency}
      </span>
      {currency && currency !== localCurrency && (
        <div className="Currency tw-text-secondary tw-font-medium">
          {formatBalance(convertCentsToCurrency(amount))} {currency}
        </div>
      )}
    </Box>
  )
}

const TransactionStatusTmpl: FC<ITransactionStatusTmplProps> = ({ status, className }) => {
  return (
    <div
      className={cn({
        'tw-text-teal': status === TransactionStatus.Success,
        'tw-text-warning': status === TransactionStatus.Pending,
        'tw-text-danger': status === TransactionStatus.Failed,
      }, className)}
    >
      {transactionExecutionTextByStatusMap[status]}
    </div>
  )
}

const transactionDate = (date: string) => {
  if (!date) return '';
  return format(new Date(date), 'yyyy/MM/dd hh:mm');
}

function DataRow(props: { data: ICardTransactionDto }) {
  const { time, type, amount, status, currency, localAmount, localCurrency, description } = props.data;

  return (
    <>
      <TRow>
        {/* Start - Desktop cells*/}
        <TCell className="tw-hidden xl:tw-table-cell">{transactionDate(time)}</TCell>
        <TCell className="tw-hidden xl:tw-table-cell">{getOperationTypeText(type)}</TCell>
        <TCell className="tw-hidden xl:tw-table-cell">{description}</TCell>
        <TCell className="tw-hidden xl:tw-table-cell">
          <AmountTmpl
            amount={amount}
            currency={currency}
            localAmount={localAmount}
            localCurrency={localCurrency}
            sx={{'.Currency': { fontSize: '10px' }}}
          />
        </TCell>
        <TCell className={cn(styles.transactionStatus, 'tw-hidden xl:tw-table-cell')}>
          <TransactionStatusTmpl status={status}></TransactionStatusTmpl>
        </TCell>
        {/* End - Desktop cells*/}

        {/* Start - Mobile cells*/}
        <TCell className="xl:tw-hidden">
          <div className="tw-text-sm">{getOperationTypeText(type)}</div>
          <div className="tw-text-secondary tw-text-xs">{transactionDate(time)}</div>
        </TCell>
        <TCell className="xl:tw-hidden tw-text-end">
          <AmountTmpl
            amount={amount}
            currency={currency}
            localAmount={localAmount}
            localCurrency={localCurrency}
            sx={{'.LocalCurrency': { fontSize: '12px' }, '.Currency': { fontSize: '10px' }}}
          />
          <TransactionStatusTmpl status={status} className="tw-text-xs"></TransactionStatusTmpl>
        </TCell>
        {/* End - Mobile cells*/}
      </TRow>
    </>
  );
}

export const CardTransactionsPage: FC = observer(() => {
  const { cardId = '' } = useParams<{ cardId: string }>();
  const store = useLocalObservable(() => {
    const store = createCardTransactionsStore();
    store.setFilters({
      fromDate: startOfDay(subDays(new Date(), 30)).toISOString(), // Початок 30-денного періоду
      toDate: endOfDay(new Date()).toISOString() // Поточний день
    });
    return store;
  });

  useEffect(() => {
    // Load transactions based on filters
    store.loadCardTransactions(cardId, store.filters);
  }, [cardId, store.filters]);

  useEffect(() => {
    // Check if the period should be extended to 90 days if there is no data for 30 days
    if (!store.loading && store.transactions.length === 0 && store.filters.fromDate === startOfDay(subDays(new Date(), 30)).toISOString()) {
      store.setFilters({
        fromDate: startOfDay(subDays(new Date(), 90)).toISOString(), // Start of 90-day period
        toDate: endOfDay(new Date()).toISOString() // Current day
      });
    }
  }, [store.loading, store.transactions.length]);

  const handleChangeDateFilter = useCallback((range: DatePickerStateModel) => {
    const fromDate = startOfDay(range.startDate).toISOString();
    const toDate = endOfDay(range.endDate as Date).toISOString();
    if (store.filters.fromDate !== fromDate || store.filters.toDate !== toDate) {
      store.setFilters({ fromDate, toDate });
    }
  }, []);

  return (
    <PageContainer isRegular>
      <h3 className="tw-text-1.5xl">Card Transaction History</h3>
      <div className={cn(styles.TransactionsContainer, 'tw-mt-7 xl:tw-mt-6')}>
        <div className="tw-border-b tw-border-b-secondary-300 tw-pb-2">
          <label className="tw-text-secondary tw-text-sm">Date range</label>
          <DatePicker
            hasIcon
            startDate={new Date(store.filters.fromDate as string)}
            endDate={new Date(store.filters.toDate as string)}
            onClose={handleChangeDateFilter}
            sx={{width: 'max-content', '.dateContainer': { borderBottom: 'none' }}}
          ></DatePicker>
        </div>
        <Table className={styles.transactionsTable}>
          <TBody>
            <TRow classname="tw-hidden xl:tw-table-row">
              <THead>Initiation date</THead>
              <THead>Operation</THead>
              <THead>Description</THead>
              <THead>Transaction amount</THead>
              <THead classname={styles.transactionStatus}>Status</THead>
            </TRow>
            {!store.loading && !!store.transactions.length && store.transactions
              .map((data, index) => <DataRow key={data.id ?? index} data={data}/>)}
            {store.loading &&
                <TRow>
                    <TCell colSpan={4}>
                        <div className="tw-flex-centering"><Loader/></div>
                    </TCell>
                </TRow>
            }
            {!store.loading && !store.transactions.length &&
                <TRow>
                    <TCell colSpan={4}>
                        <div className="tw-flex-centering tw-font-semibold">No data</div>
                    </TCell>
                </TRow>
            }
          </TBody>
        </Table>
      </div>
    </PageContainer>
  );
});
