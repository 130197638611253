import { FC, useEffect, useState } from 'react';
import { CardStatus } from '../../../../../../services/card-api';
import { observer } from 'mobx-react-lite';
import styles from './CardStatusOverlay.module.scss';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import Icon from '../../../../../../components/Icon';
import { Box, SxProps, Theme } from '@mui/material';

interface IStatusContent {
  title: string;
  description: string[];
  badgeBgUrl: string;
  linkText?: string;
  headerLinkUrl?: string;
}

const statusContentMap: Record<CardStatus, IStatusContent> = {
  [CardStatus.ACTIVE]: {
    title: 'Issued',
    description: ['Your card has been successfully issued. You can go to your personal account to manage your card'],
    linkText: 'Go to Personal Account',
    badgeBgUrl: '/images/success-icon-in-green-container.svg',
    headerLinkUrl: '/app/cards',
  },
  [CardStatus.KYCPending]: {
    title: 'Under Review',
    description: ['Your card application is under review.', 'Please wait.'],
    badgeBgUrl: '/images/chart-icon-in-yellow-badge.svg',
  },
  [CardStatus.KYCFailed]: {
    linkText: 'Contact support',
    title: 'Rejected',
    description: ['Your card application has been rejected', 'by the compliance department'],
    badgeBgUrl: '/images/stop-icon-in-red-container.svg',
    headerLinkUrl: 'mailto:support@vpluspay.hk',
  },
  [CardStatus.WAITING_FOR_PAYMENT]: {
    linkText: 'Contact support',
    title: 'Payment Pending',
    description: ['To receive your card, please', 'complete the payment'],
    badgeBgUrl: '/images/wait-frame-icon-with-green-container.svg',
    headerLinkUrl: 'mailto:support@vpluspay.hk',
  },
};

const defaultContent: IStatusContent = {
  linkText: '',
  title: '',
  description: [],
  badgeBgUrl: '',
};

export interface ICardStatusOverlayProps {
  status: CardStatus;
  nextBtnLink?: string | null;
  headerLink?: string | null;
  isShowLink?: boolean;
  size?: 'sm' | 'md';
  className?: string;
  sx?: SxProps<Theme>,
}

export const CardStatusOverlay: FC<ICardStatusOverlayProps> = observer((
  {
    status,
    nextBtnLink,
    headerLink,
    isShowLink = true,
    size = 'md',
    className,
    sx,
}) => {
  const [content, setContent] = useState<IStatusContent>(defaultContent);
  
  useEffect(() => {
    setContent(statusContentMap[status] ?? defaultContent);
  }, [status]);
  
  return (
    <Box className={cn(styles.CardOverlayWrapper, className, 'CardOverlayWrapper')} sx={sx}>
      <div className={cn(
        styles.CardOverlay,
        styles['CardOverlay_' + status],
        styles['CardOverlay_' + size],
        'CardOverlay',
        'CardOverlay_' + size,
      )}>
        <div className={cn(styles.CardOverlayHeader, 'CardOverlayHeader')}>
          <div className={cn(styles.CardOverlayBadge, 'CardOverlayBadge')} style={{ backgroundImage: `url(${content.badgeBgUrl})` }}></div>
          {
            content.linkText && isShowLink &&
              <NavLink
                  className={cn(
                      styles.CardOverlayHeaderLink,
                      'CardOverlayHeaderLink',
                      {
                        TextWarning: status === CardStatus.KYCPending,
                        TextError: status === CardStatus.KYCFailed,
                        TextSuccess: status === CardStatus.ACTIVE,
                        'tw-text-softGreen': status === CardStatus.WAITING_FOR_PAYMENT,
                      }
                  )}
                  to={headerLink || content.headerLinkUrl || ''}
              >{content.linkText}</NavLink>
          }
        </div>
        <div>
          <div className={cn(styles.CardInformationTitle, 'CardInformationTitle')}>{content.title}</div>
          <div className={cn(
            styles.CardInformationDescription,
              {'CardInformationDescription_withBtnLink': nextBtnLink },
              'CardInformationDescription',
            )
          }>
            <div className="tw-flex-1">
              {content.description.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
            {nextBtnLink &&
              <NavLink
                className={cn(styles.NextBtnLink, 'NextBtnLink')}
                to={nextBtnLink}>
                <Icon icon="arrow-right-lined-slim" />
              </NavLink>
            }
          </div>
        </div>
      </div>
    </Box>
  )
});
